// import radar from './radar.png';
const radar = require("./radar.png");

const config = {
    config: {
        origin: {
            x: -3168 + 10,
            y: 1762 + 600,
        },
        scale: 4.0,
    },
    file: radar,
};

export default config;
