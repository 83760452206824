import { Flex, useColorModeValue } from "@chakra-ui/react";
import { IMatch } from "../../types/IMatch";
import PlayerStatsType from "../../types/PlayerStatsType";
import { Team } from "./Team";

interface MapsPropTypes {
    matchData: IMatch;
    stats: {
        data: { [K in keyof any]: PlayerStatsType };
        error: { [K in keyof any]: PlayerStatsType };
    };
    matchId: string;
    viewType: string;
}

export function Maps({ matchData, stats, matchId, viewType }: MapsPropTypes) {
    const bgColor = useColorModeValue("#e5e8ea", "#101010");
    const team1 =
        matchId === "current" && matchData.playerFaction === "faction1"
            ? "faction2"
            : "faction1";
    const team2 = matchId === "current" ? matchData.playerFaction : "faction2";

    return (
        <Flex
            w={"full"}
            direction={{ base: "column", md: "row" }}
            flexWrap="wrap"
            justifyContent={"left"}
            overflowX="scroll"
            css={{
                "&::-webkit-scrollbar": {
                    width: "11px",
                    height: "11px",
                },
                "&::-webkit-scrollbar-track": {
                    background: "transparent",
                    borderRadius: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: useColorModeValue("#b2b2b2", "#323232"),
                    borderRadius: "6px",
                    border: `3px solid ${bgColor}`,
                },
            }}
        >
            <Team
                teamData={matchData.factions[team1]}
                matchData={matchData}
                stats={stats}
                type={viewType}
                borderBottom="1px solid"
                pb={6}
                mb={5}
            />
            <Team
                teamData={matchData.factions[team2]}
                matchData={matchData}
                stats={stats}
                type={viewType}
                pb={2}
            />
        </Flex>
    );
}

