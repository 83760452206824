import {
    Heading,
    HStack,
    Image,
    Input,
    Spacer,
    Text,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { focusBorderColor } from "../components/theme/Theme";
import * as Logs from "../utils/Logs";
import API from "../services/API";
import config from "../config";
import { CustomBox } from "./CustomBox";
import { CustomSpinner } from "./CustomSpinner";
import AuthContext from "../contexts/AuthContext";
import ListIcon from "./icons/ListIcon";
import { SmallCloseIcon } from "@chakra-ui/icons";

function SearchBox({ onPlayerClick }) {
    const defaultResults = { results: [], total_count: 0 };
    const [isLoading, setIsLoading] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [timeoutVar, setTimeoutVar] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [results, setResults] = useState(defaultResults);

    const context = useContext(AuthContext);

    const inputBgColor = useColorModeValue("light.300", "secondary.450");
    const inputBgColorHover = useColorModeValue("light.200", "#1e1e1e");

    // const changeHandler = (e) => setSearch(e.target.value.trim());
    const changeHandler = (e) => {
        const search = e.target.value.trim();
        setSearchTerm(search);
        if (search.length < 2) {
            setIsLoading(false);
            setResults(defaultResults);
            if (timeoutVar !== null) clearTimeout(timeoutVar);
            return;
        }
        setIsLoading(true);
        if (timeoutVar !== null) clearTimeout(timeoutVar);
        const timeout = setTimeout(() => {
            Logs.post("search", { value: search }, context.sessionkey);
            API.get(
                config.faceitProxy +
                    config.faceitEndpoints.search +
                    encodeURI(search)
            )
                .then((res) => setResults(res.data.payload.players))
                .catch((err) => console.log(err))
                .finally(() => setIsLoading(false));
        }, 400);
        setTimeoutVar(timeout);
    };

    return (
        <div style={{ position: "relative" }}>
            <Input
                focusBorderColor={focusBorderColor}
                bgColor={inputBgColor}
                _hover={{
                    bgColor: inputBgColorHover,
                }}
                placeholder="Search for a player"
                onChange={changeHandler}
                onFocus={() => setShowSearch(true)}
                value={searchTerm}
            />
            {searchTerm.length >= 2 && showSearch && (
                /*(isLoading || results.results.length !== 0) && */ <CustomBox
                    pos={"absolute"}
                    border="1px solid"
                    borderColor={"#555"}
                    zIndex={999}
                >
                    {(isLoading && (
                        <HStack w="full" justifyContent={"center"}>
                            <CustomSpinner size={"md"} />
                        </HStack>
                    )) || (
                        <VStack alignItems={"flex-start"} spacing={1}>
                            <HStack alignItems={"center"} spacing={1} w="full">
                                <ListIcon />
                                <Text fontSize={14}>
                                    <b>{results.total_count}</b> player
                                    {results.total_count !== 1 && "s"} found.
                                </Text>
                                <Spacer />
                                <SmallCloseIcon
                                    cursor="pointer"
                                    onClick={() => setShowSearch(false)}
                                />
                            </HStack>

                            {results.results.length !== 0 &&
                                results.results.map((e) => (
                                    <Player
                                        data={e}
                                        onClick={onPlayerClick}
                                        setShowSearch={setShowSearch}
                                        setSearchTerm={setSearchTerm}
                                    />
                                ))}
                        </VStack>
                    )}
                </CustomBox>
            )}
        </div>
    );
}

export function Player({ data, onClick, setShowSearch, setSearchTerm }) {
    const csgo = data.games.find((e) => e.name === "csgo");
    const level = csgo !== undefined ? csgo.skill_level : 0;

    return (
        <HStack
            width={"full"}
            py={1}
            borderRadius={8}
            key={data.id}
            onClick={() => {
                onClick(data);
                setShowSearch(false);
                setSearchTerm("");
            }}
            cursor="pointer"
        >
            <Image
                alt="Profile Avatar"
                fit="fill"
                fallbackSrc={config.defaultAvatar}
                w={8}
                h={8}
                rounded={"full"}
                border={"1px solid"}
                borderColor={"secondary.600"}
                src={data.avatar !== undefined ? data.avatar : ""}
                sx={{
                    imageRendering: "-webkit-optimize-contrast",
                }}
                title={data.nickname}
            />
            <Heading
                fontSize={17}
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                width={"full"}
                title={data.nickname}
            >
                {data.nickname}
            </Heading>
            <div
                title={"FACEIT Level " + level}
                className="faceit-level"
                style={{
                    width: "48px",
                    height: "28px",
                    backgroundImage: `url('/images/faceit_levels/${level}.svg')`,
                }}
            ></div>
        </HStack>
    );
}

export default SearchBox;
