import { createIcon } from "@chakra-ui/icons";

const LobbyIcon = createIcon({
    displayName: "Check Lobby Icon",
    viewBox: "0 0 20 14",
    path: [
        <path
            key="ficpi1"
            d="M10 0C5.45455 0 1.57273 2.90267 0 7C1.57273 11.0973 5.45455 14 10 14C14.5455 14 18.4273 11.0973 20 7C18.4273 2.90267 14.5455 0 10 0ZM10 11.6667C7.49091 11.6667 5.45455 9.576 5.45455 7C5.45455 4.424 7.49091 2.33333 10 2.33333C12.5091 2.33333 14.5455 4.424 14.5455 7C14.5455 9.576 12.5091 11.6667 10 11.6667ZM10 4.2C8.49091 4.2 7.27273 5.45067 7.27273 7C7.27273 8.54933 8.49091 9.8 10 9.8C11.5091 9.8 12.7273 8.54933 12.7273 7C12.7273 5.45067 11.5091 4.2 10 4.2Z"
            fill="currentColor"
            // fill="#EBEFF3"
        />,
    ],
});

export default LobbyIcon;
