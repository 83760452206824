import {
    Alert,
    AlertIcon,
    AlertTitle,
    Divider,
    Flex,
    Heading,
    HStack,
    Image,
    Tag,
    Text,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import PageLayout from "../components/layout/PageLayout";
import { useNavigate } from "react-router-dom";
import { ReactElement, useContext, useEffect, useState } from "react";
import AuthContext from "../contexts/AuthContext";
import { CustomBox } from "../components/CustomBox";
import API from "../services/API";
import config from "../config";
import { CustomSpinner } from "../components/CustomSpinner";
import { ReputationBadge } from "../components/ReputationBadge";
import ReputationData from "../types/ReputationData";
import PlayerType from "../types/PlayerType";
import * as Logs from "../utils/Logs";

interface LobbyObjTypes {
    exists: boolean;
    type: string;
    name?: string;
    members: Array<string>;
    id: string;
}

interface LobbyDataTypes {
    lobby: LobbyObjTypes;
    party: LobbyObjTypes;
}

function LobbyPage() {
    // const navigate = useNavigate();
    const context = useContext(AuthContext);

    const [lobbyData, setLobbyData]: [LobbyDataTypes, any] = useState(null);
    const [reputations, setReputations] = useState(null);
    const [playersData, setPlayersData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        window.scroll(0, 0);
        Logs.post("page", { page: "/lobby" }, context.sessionkey);

        API.get(config.apiEndpoints.lobby, { cache: false })
            .then((res) => {
                const lobbyData = res.data;
                setLobbyData(lobbyData);

                if (!lobbyData.party.exists && !lobbyData.lobby.exists)
                    return setIsLoading(false);

                // get list from faceit & multiple from fcitrep
                // for lobby & party

                const ids = [
                    ...(lobbyData["party"].members ?? []),
                    ...(lobbyData["lobby"].members ?? []),
                ];

                API.post(
                    config.faceitProxy + config.faceitEndpoints.userList,
                    ids
                )
                    .then((res) => setPlayersData(res.data.payload))
                    .catch((err) => console.log(err))
                    .finally(() => setIsLoading(false));

                API.post(config.apiEndpoints.multiple, ids)
                    .then((res) => setReputations(res.data.data))
                    .catch((err) => console.log(err))
                    .finally(() => setIsLoading(false));
            })
            .catch((err) => console.log(err));
        // .finally(() => setIsLoading(false));
    }, []);

    const dividerColor = useColorModeValue("secondary.200", "light.900");
    const alertBorderColor = useColorModeValue("#fbb", "#faa");

    return (
        <PageLayout py={{ base: 4, md: 8 }} pagetitle={"Current Lobby"}>
            <VStack align={"flex-start"} spacing={3}>
                <HStack spacing={5}>
                    <Heading fontSize={"3xl"} mb={1}>
                        Current lobby
                    </Heading>
                    {isLoading && <CustomSpinner alignSelf="flex-start" />}
                </HStack>
                {lobbyData !== null && (
                    <Flex
                        w={"full"}
                        justifyContent="space-between"
                        direction={{ base: "column", md: "row" }}
                        flexWrap="wrap"
                    >
                        {!lobbyData.lobby.exists && !lobbyData.party.exists && (
                            <Alert
                                status="error"
                                borderRadius={6}
                                border="1px solid"
                                borderColor={alertBorderColor}
                            >
                                <AlertIcon />
                                <AlertTitle mr={2}>
                                    You are not in a lobby.
                                </AlertTitle>
                            </Alert>
                        )}
                        {lobbyData.lobby.exists && (
                            <Lobby
                                data={lobbyData.lobby}
                                reputations={reputations}
                                playersData={playersData}
                                sessionkey={context.sessionkey}
                            />
                        )}
                        {lobbyData.lobby.exists && lobbyData.party.exists && (
                            <Divider borderColor={dividerColor} mt={3} mb={7} />
                        )}
                        {lobbyData.party.exists && (
                            <Lobby
                                data={lobbyData.party}
                                reputations={reputations}
                                playersData={playersData}
                                sessionkey={context.sessionkey}
                            />
                        )}
                    </Flex>
                )}
                \
            </VStack>
        </PageLayout>
    );
}

interface LobbyPropTypes {
    data: LobbyObjTypes;
    reputations: ReputationData;
    playersData: PlayerType;
    sessionkey: string;
}

function Lobby({ data, reputations, playersData, sessionkey }: LobbyPropTypes) {
    let playerList: Array<ReactElement> = [];
    if (!data.exists) return null;

    for (const id of data.members) {
        const playerData = playersData !== null ? playersData[id] : null;
        const reputation = reputations !== null ? reputations[id] : null;

        // const createdDate = new Date(
        //     playerData !== null ? playerData.createdAt : null
        // );
        // const formattedDate = playerData !== null ? strftime("%e %b %Y, %Hh%M", createdDate) : "";
        const avatar = playerData !== null ? playerData.avatar : "";
        const nickname =
            playerData !== null
                ? playerData.nickname
                : reputation !== null
                ? reputation.nickname
                : null;
        const rep = reputation !== null ? reputation.rep : null;

        playerList.push(
            <PlayerCard
                nickname={nickname}
                avatar={avatar}
                reputation={rep}
                datefr={""}
                id={id}
                sessionkey={sessionkey}
                key={playerList.length}
            />
        );
    }

    return (
        <>
            <Tag fontSize={19} mb={3} w="fit-content">
                {data.name}
            </Tag>
            <Flex w="full" justifyContent={"space-around"} flexWrap="wrap">
                {playerList}
            </Flex>
        </>
    );
}

function PlayerCard({ nickname, avatar, reputation, datefr, id, sessionkey }) {
    const navigate = useNavigate();
    const playerClickHandler = () => {
        Logs.post(
            "click",
            {
                type: "player",
                value: id,
            },
            sessionkey
        );
        navigate("/player/" + nickname);
    };

    // todo: set a fixed width because it won't be always 5 cards (can be even more than 5!)
    return (
        <CustomBox
            flexBasis={{
                base: "100%",
                sm: "40%",
                md: "22%",
                lg: "18%",
            }}
            py={6}
            cursor="pointer"
            onClick={playerClickHandler}
        >
            <VStack>
                <Image
                    alt="Profile Avatar"
                    fit="fill"
                    fallbackSrc={config.defaultAvatar}
                    w={20}
                    h={20}
                    rounded={"full"}
                    border={"1px solid"}
                    borderColor={"secondary.400"}
                    src={avatar}
                    sx={{ imageRendering: "-webkit-optimize-contrast" }}
                />
                <Text fontSize={18}>{nickname}</Text>
                {reputation !== null && (
                    <ReputationBadge
                        color={config.reputations[reputation].color}
                        text={config.reputations[reputation].reputation}
                        size={15}
                    />
                )}
            </VStack>
        </CustomBox>
    );
}

export default LobbyPage;
