import { Heading, HStack, Image, useColorModeValue } from "@chakra-ui/react";
import { Dispatch, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import API from "../../services/API";
import PlayerType from "../../types/PlayerType";

function Player({ playerId }) {
    const [playerData, setPlayerData]: [PlayerType, Dispatch<any>] =
        useState(null);
    const [error, setError]: [PlayerType, Dispatch<any>] = useState(null);

    useEffect(() => {
        if (playerId === null) setError(true);
        API.get(config.faceitProxy + config.faceitEndpoints.user + playerId)
            .then((res) => setPlayerData(res.data.payload))
            .catch((error) => setError(error));
    }, []);

    const nicknameColor = useColorModeValue("secondary.100", "light.800");
    const navigate = useNavigate();

    const clickHandler = () => {
        if (playerData === null || error !== null) return;
        navigate("/player/" + playerData.nickname);
    };

    const displayNickname =
        playerData === null
            ? error === null
                ? "Loading..."
                : "Unexistent player"
            : playerData.nickname;

    return (
        <HStack>
            <Image
                alt="Profile Avatar"
                fit="fill"
                fallbackSrc={config.defaultAvatar}
                w={7}
                h={7}
                rounded={"full"}
                border={"1px solid"}
                borderColor={"secondary.600"}
                src={playerData === null ? "" : playerData.avatar}
                sx={{
                    imageRendering: "-webkit-optimize-contrast",
                }}
            />
            <Heading
                fontSize={16}
                color={playerData === null ? nicknameColor : ""}
                fontStyle={playerData === null ? "italic" : "normal"}
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                cursor="pointer"
                onClick={clickHandler}
                _hover={{ color: "brand.500" }}
                _active={{ color: "brand.600" }}
                style={{ transition: "color 150ms" }}
                title={displayNickname}
            >
                {displayNickname}
            </Heading>
        </HStack>
    );
}

export default Player;
