import maps from ".";
import { IUtilityPos } from "../../types/IUtilityPos";

interface IProps {
    map: string;
    utility: IUtilityPos[];
}

export const utilityTypes = {
    smoke: "#1aff8c",
    flashbang: "#0ff",
    explosive: "#f70",
    molotov: "#f00",
    incendiary: "#f00",
};

function Radar(props: IProps) {
    const map = maps[props.map];
    if (!map) return null;

    const mapSize = 512;
    const scale = mapSize / 1024;
    const utilityList = [];

    for (const utility of props.utility) {
        const mapPos = gameUnitsToRadar(utility.pos, map.config);

        // console.log("-------------------------------");
        // console.log("utility", utility);
        // console.log("pos", mapPos);

        //// -TODO
        // THE UTILITY POSITION ON THE MAP IS NOT CORRECT >:(
        // something is off, please check csgo coordinates of the utility in-game
        // and test if it's correct
        // also check offsets and see if the pos is according to the map - ITS WAS THE OFFSETS

        utilityList.push(
            <div
                style={{
                    position: "absolute",
                    top: mapPos.y * scale - 4,
                    left: mapPos.x * scale - 4,
                    padding: "7px",
                    background: utilityTypes[utility.type as any],
                    borderRadius: "50%",
                    border: "1px solid #222",
                }}
            ></div>
        );
    }

    return (
        <div style={{ position: "relative" }}>
            <img
                src={map.file}
                alt={"Map " + props.map}
                width={`${mapSize}px`}
                style={{ opacity: 0.6 }}
            />
            {utilityList}
        </div>
    );
}

const gameUnitsToRadar = (pos, config) => {
    return {
        x: (pos.x - config.origin.x) / config.scale,
        y: 1024 - (pos.y + config.origin.y) / config.scale,
    };
};

export default Radar;
