import axios from "axios";
import config from "../config";
import { setupCache } from "axios-cache-interceptor";

// const source = axios.CancelToken.source();

const API = setupCache(
    axios.create({
        baseURL: config.apiUrl,
        // cancelToken: source,
    }),
    {
        ttl: config.axiosCache,
    }
);

export default API;
