const config = {
    apiUrl: process.env.REACT_APP_API_URL || "/api/v1",
    socketUrl: process.env.REACT_APP_SOCKETS || "/",
    // apiUrl: "https://api-faceit-reputation.rmeev.net/v2",
    apiEndpoints: {
        appeals: "/appeals/",
        contributors: "/contributors/",
        comment: "/comments/",
        event: "/event/",
        list: "/list/",
        // listpeople: "/listpeople/", // deprecated
        lobby: "/lobby/",
        logout: "/logout/",
        match: "/match/",
        me: "/me/",
        multiple: "/multiple/",
        notifications: "/notifications/",
        player: "/player/",
        players: "/players/",
        playerComments: "/players/{player_id}/comments/",
        playerStats: "/playerStats/",
        premadeMapMatches: "/premadeMapMatches/",
        recentMatches: "/recentMatches/",
        reports: "/reports/",
        state: "/state/",
        sessions: "/admin/sessions/",
        teamMatches: "/teamMatches/",
        teammates: "/teammates/",
        votes: "/votes/",
    },
    appName: "FACEIT Reputation",
    defaultLang: "en",
    axiosCache: 5 * 60 * 1000,
    defaultAvatar: "/images/default_avatar.svg",
    contactEmail: "hello@rmeev.net",

    demoParserApi: process.env.REACT_APP_DEMO_PARSER_API || "https://demo-parser.herokuapp.com/v1/match/",
    faceitConnect: {
        baseUrl: process.env.REACT_APP_CONNECT_API_URL,
        endpoints: { teamspeakOnline: "/teamspeak/isOnline" },
    },
    teamspeak: "ts3server://faceitpt",

    faceitClientId: process.env.REACT_APP_FACEIT_CLIENT_ID ?? "",

    // homeUrl: "/home",

    animatedLogoTime: 1400,

    adminPermissions: ["admin", "superadmin"],

    eloLevels: {
        0: [0, 0],
        1: [1, 800],
        2: [801, 950],
        3: [951, 1100],
        4: [1101, 1250],
        5: [1251, 1400],
        6: [1401, 1550],
        7: [1551, 1700],
        8: [1701, 1850],
        9: [1851, 2000],
        10: [2001, null],
    },

    faceitProxy: process.env.REACT_APP_FACEIT_PROXY_URL || "https://faceitproxy.rmeev.tech",
    faceitEndpoints: {
        player: "/player/",
        user: "/user/",
        search: "/search/",
        matches: "/matches/",
        userList: "/userList/",
        democracy: "/democracy/",
        match: "/match/",
    },

    faceitMatchRoomUrl: "https://www.faceit.com/en/csgo/room/",
    faceitPlayerProfileUrl: "https://www.faceit.com/en/players/",

    // used for: changing the icon of rep
    //           in player page
    positiveReputations: ["clean", "contributor", "good"],

    // used for:
    //    player page: top fade
    //    player page: reputation
    reputations: {
        clean: {
            color: "#29a329",
            text: "This player is clean.",
            reputation: "Clean",
        },
        good: {
            color: "#007acc",
            text: "This player has been reported as a good player.",
            reputation: "Good",
        },
        blacklist: {
            color: "#ff0000",
            text: "This player is in the blacklist.",
            reputation: "BLACKLIST",
        },
        careful: {
            color: "#ff751a",
            text: "Be careful. This player was reported.",
            reputation: "Careful",
        },
        contributor: {
            color: "#7300e6",
            text: "This player is a contributor.",
            reputation: "Contributor",
        },
        reports: {
            color: "#d5ab07",
            text: "This player has an unconfirmed report.",
            reputation: "Reports",
        },
    },

    maps: {
        de_ancient: {
            label: "Ancient",
            icon: "/images/map_icons/de_ancient.png",
            cover: "/images/map_cover/de_ancient.webp",
        },
        de_cache: {
            label: "Cache",
            icon: "/images/map_icons/de_cache.png",
            cover: "/images/map_cover/de_cache.webp",
        },
        de_cbble: {
            label: "Cobblestone",
            icon: "/images/map_icons/de_cbble.png",
            cover: "/images/map_cover/de_cbble.webp",
        },
        de_dust2: {
            label: "Dust 2",
            icon: "/images/map_icons/de_dust2.png",
            cover: "/images/map_cover/de_dust2.webp",
        },
        de_inferno: {
            label: "Inferno",
            icon: "/images/map_icons/de_inferno.png",
            cover: "/images/map_cover/de_inferno.webp",
        },
        de_mirage: {
            label: "Mirage",
            icon: "/images/map_icons/de_mirage.png",
            cover: "/images/map_cover/de_mirage.webp",
        },
        de_nuke: {
            label: "Nuke",
            icon: "/images/map_icons/de_nuke.png",
            cover: "/images/map_cover/de_nuke.webp",
        },
        de_overpass: {
            label: "Overpass",
            icon: "/images/map_icons/de_overpass.png",
            cover: "/images/map_cover/de_overpass.webp",
        },
        de_train: {
            label: "Train",
            icon: "/images/map_icons/de_train.png",
            cover: "/images/map_cover/de_train.webp",
        },
        de_tuscan: {
            label: "Tuscan",
            icon: "",
            cover: "/images/map_cover/de_tuscan.jpg",
        },
        de_vertigo: {
            label: "Vertigo",
            icon: "/images/map_icons/de_vertigo.png",
            cover: "/images/map_cover/de_vertigo.webp",
        },
        de_anubis: {
            label: "Anubis",
            icon: "/images/map_icons/de_anubis.png",
            cover: "/images/map_cover/de_anubis.webp",
        },
    },

    mapPool: ["de_mirage", "de_inferno", "de_dust2", "de_vertigo", "de_nuke", "de_overpass", "de_ancient", "de_anubis", "de_train"],

    partyColors: ["#f00", "#0f0", "#2fc6ec", "#fcba03", "#d800ff", "#002bff", "#37b38c", "#fff", "#6703ea", "#b13f0e"],

    reportOptions: {
        report: "Report",
        careful: "Careful",
        blacklist: "Blacklist",
        good: "Good",
    },

    defaultMapsStatsViewType: "last_matches_stats",

    matchStates: {
        VOTING: {
            text: "Voting...",
            blinking: true,
            color: "yellow",
            isPick: false,
            delay: 5000,
            message: "You must wait for the voting to end.",
            pistolRoundData: false,
        },
        CONFIGURING: {
            text: "Configuring...",
            blinking: true,
            color: "yellow",
            isPick: true,
            delay: 5000,
            message: "Ongoing match",
            pistolRoundData: true,
        },
        READY: {
            text: "Playing",
            blinking: true,
            color: "green",
            isPick: true,
            delay: 30000,
            message: "Ongoing match",
            pistolRoundData: true,
        },
        ONGOING: {
            text: "Playing",
            blinking: true,
            color: "green",
            isPick: true,
            delay: 30000,
            message: "Ongoing match",
            pistolRoundData: true,
        },
        FINISHED: {
            text: "Finished",
            blinking: false,
            color: "gray",
            isPick: true,
            delay: 0,
            message: "This match has ended.",
            pistolRoundData: true,
        },
        CANCELLED: {
            text: "Cancelled",
            blinking: false,
            color: "red",
            isPick: false,
            delay: 0,
            message: "This match has been cancelled.",
            pistolRoundData: false,
        },
    },
};

export default config;
