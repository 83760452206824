// import radar from './radar.png';
const radar = require("./radar.png");

const config = {
    config: {
        origin: {
            x: -2000,
            y: 3250,
        }, // MUST FIX
        scale: 5.5,
    },
    file: radar,
};

export default config;
