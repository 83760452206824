import { createIcon } from "@chakra-ui/icons";

const MapsIcon = createIcon({
    displayName: "Maps Icon",
    viewBox: "0 0 17 15",
    path: [
        <path
            key="mi1"
            d="M0 12.001L1.2207e-05 1.7C0.250012 0.800073 0.934478 0 2.60001 0V10.7C1.20001 10.7 0.689961 10.7747 0 12.001Z"
            // fill="#EBEFF3"
            fill="currentColor"
        />,
        <path
            key="mi2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.19995 2.60005V10.6999C3.09493 10.9496 2.98914 11.0613 2.69995 11.1999C1.60003 11.1001 0.495247 11.5934 0.580027 12.3001C0.700022 13.3001 1.27178 14.2465 2.69995 14.2999C3.31112 14.3115 3.58761 14.239 4.199 14.0789C4.34414 14.0408 4.50815 13.9979 4.7 13.9499C6.23115 13.5733 7.11088 13.376 8.70002 13.3999C11.602 13.3692 13.1545 13.9448 15.7615 14.9115C15.84 14.9406 15.9195 14.9701 16 14.9999C16.5023 14.9915 16.6483 14.8038 16.7 14.1999V4.19991C13.7 1.20003 7.20001 0.700027 3.19995 2.60005ZM8.2828 4.85365L7.39686 5.29128C7.19525 5.47757 7.14627 5.60017 7.25781 5.875L8.35156 7.55469L6.54688 8.78125C6.3778 8.96614 6.35587 9.08557 6.45312 9.32812L6.96094 10.1406C7.18141 10.3436 7.31206 10.3509 7.54688 10.2578L9.33594 9.05469L10.2266 10.418C10.3963 10.5804 10.511 10.5852 10.7266 10.543L11.5859 10.1172C11.7781 9.95296 11.8561 9.84533 11.7383 9.51953L10.793 8.06641L12.3008 7.04297C12.4347 6.8598 12.4652 6.74732 12.375 6.51562L11.8266 5.65953C11.6299 5.49777 11.4989 5.47509 11.2715 5.57948L9.80918 6.56149L8.75779 4.96573C8.59622 4.82747 8.49302 4.79977 8.2828 4.85365Z"
            fill="currentColor"
        />,
    ],
});

export default MapsIcon;
