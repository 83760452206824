import { createIcon } from "@chakra-ui/icons";

const LikeIcon = createIcon({
    displayName: "Like Icon",
    viewBox: "0 0 33 33",
    path: [
        <path
            d="M0 30H6V12H0V30ZM33 13.5C33 11.85 31.65 10.5 30 10.5H20.535L21.96 3.645L22.005 3.165C22.005 2.55 21.75 1.98 21.345 1.575L19.755 0L9.885 9.885C9.33 10.425 9 11.175 9 12V27C9 28.65 10.35 30 12 30H25.5C26.745 30 27.81 29.25 28.26 28.17L32.79 17.595C32.925 17.25 33 16.89 33 16.5V13.5Z"
            fill="currentColor"
            key="li1"
        />,
    ],
});

export default LikeIcon;
