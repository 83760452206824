import { createIcon } from "@chakra-ui/icons";

const DislikeIcon = createIcon({
    displayName: "Dislike Icon",
    viewBox: "0 0 33 33",
    path: [
        <path
            d="M21 3H7.5C6.255 3 5.19 3.75 4.74 4.83L0.21 15.405C0.075 15.75 0 16.11 0 16.5V19.5C0 21.15 1.35 22.5 3 22.5H12.465L11.04 29.355L10.995 29.835C10.995 30.45 11.25 31.02 11.655 31.425L13.245 33L23.13 23.115C23.67 22.575 24 21.825 24 21V6C24 4.35 22.65 3 21 3ZM27 3V21H33V3H27Z"
            fill="currentColor"
            key="di1"
        />,
    ],
});

export default DislikeIcon;
