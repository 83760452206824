import { createIcon } from "@chakra-ui/icons";

const EnglishFlag = createIcon({
  displayName: "English Flag",
  viewBox: "0 0 512 336",
  path: [
    <path
      key="enfl1"
      d="M473.655 0.000976562H38.345C17.167 0.000976562 0 17.168 0 38.346V297.105C0 318.282 17.167 335.45 38.345 335.45H473.655C494.832 335.45 512 318.283 512 297.105V38.346C512 17.168 494.833 0.000976562 473.655 0.000976562Z"
      fill="#41479B"
    />,
    <path
      key="enfl2"
      d="M511.469 32.007C508.447 13.848 492.672 0 473.655 0H463.678L300.138 107.147V0.000999451H211.862V107.148L48.322 0.000999451H38.345C19.328 0.000999451 3.553 13.848 0.531 32.008L140.309 123.588H0V211.864H140.309L0.531 303.442C3.553 321.601 19.328 335.449 38.345 335.449H48.322L211.862 228.302V335.449H300.138V228.302L463.678 335.449H473.655C492.672 335.449 508.447 321.602 511.469 303.442L371.691 211.862H512V123.586H371.691L511.469 32.007Z"
      fill="#F5F5F5"
    />,
    <path
      key="enfl3"
      d="M282.483 0.0012207H229.517V141.242H0V194.208H229.517V335.449H282.483V194.208H512V141.242H282.483V0.0012207Z"
      fill="#FF4B55"
    />,
    <path
      key="enfl4"
      d="M24.7926 332.978L211.376 211.864H178.948L9.22363 322.036C13.3766 326.883 18.7136 330.681 24.7926 332.978Z"
      fill="#FF4B55"
    />,
    <path
      key="enfl5"
      d="M346.388 211.864H313.96L494.676 329.169C499.733 325.848 503.953 321.362 506.963 316.094L346.388 211.864Z"
      fill="#FF4B55"
    />,
    <path
      key="enfl6"
      d="M4.04883 21.2001L161.779 123.587H194.207L15.4748 7.56714C10.6758 11.1391 6.74883 15.8091 4.04883 21.2001Z"
      fill="#FF4B55"
    />,
    <path
      key="enfl7"
      d="M332.566 123.588L502.601 13.2125C498.402 8.38154 493.023 4.60554 486.902 2.35254L300.138 123.588H332.566Z"
      fill="#FF4B55"
    />,
  ],
});

export default EnglishFlag;
