import { createIcon } from "@chakra-ui/icons";

const TrashIcon = createIcon({
  displayName: "Trash Icon",
  viewBox: "0 0 12 16",
  path: [
    <path
      key="ti1"
      d="M0.857143 14.2222C0.857143 15.2 1.62857 16 2.57143 16H9.42857C10.3714 16 11.1429 15.2 11.1429 14.2222V5.33333C11.1429 4.35556 10.3714 3.55556 9.42857 3.55556H2.57143C1.62857 3.55556 0.857143 4.35556 0.857143 5.33333V14.2222ZM11.1429 0.888889H9L8.39143 0.257778C8.23714 0.0977777 8.01429 0 7.79143 0H4.20857C3.98571 0 3.76286 0.0977777 3.60857 0.257778L3 0.888889H0.857143C0.385714 0.888889 0 1.28889 0 1.77778C0 2.26667 0.385714 2.66667 0.857143 2.66667H11.1429C11.6143 2.66667 12 2.26667 12 1.77778C12 1.28889 11.6143 0.888889 11.1429 0.888889Z"
      fill="currentColor"
    />,
  ],
});

export default TrashIcon;
