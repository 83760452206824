import {
    Heading,
    HStack,
    Image,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import React, { Dispatch, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import API from "../../services/API";
import PlayerType from "../../types/PlayerType";
import { strftime } from "../../utils/strftime";

const SessionPlayer = ({
    selectedSession = null,
    onClick = null,
    session = null,
    user = null,
    ...props
}) => {
    const [playerData, setPlayerData]: [PlayerType, Dispatch<any>] =
        useState(null);
    const playerid = session !== null ? session.playerid : user.playerid;

    useEffect(() => {
        API.get(config.faceitProxy + config.faceitEndpoints.user + playerid)
            .then((res) => setPlayerData(res.data.payload))
            .catch((error) => console.log(error));
    }, []);

    const date = new Date(session !== null ? session.started : null);
    const dateFormatted = session !== null ? strftime("%H:%M", date) : null;
    const selectedSessionBgColor = useColorModeValue("light.450", "#303030");
    const navigate = useNavigate();

    const nickname =
        playerData !== null
            ? playerData.nickname
            : session !== null
            ? session.nickname
            : user.nickname;

    const clickHandler = () => {
        if (props.noredirect !== undefined) return;
        navigate("/player/" + nickname);
    };

    return (
        <HStack
            width={"full"}
            bgColor={
                selectedSession !== null &&
                selectedSession.uniquekey === session.uniquekey
                    ? selectedSessionBgColor
                    : ""
            }
            px={props.px ?? 0}
            py={props.py ?? 1}
            borderRadius={8}
            cursor={onClick !== null ? "pointer" : "auto"}
            onClick={onClick}
            key={props.key}
        >
            <Image
                alt="Profile Avatar"
                fit="fill"
                fallbackSrc={config.defaultAvatar}
                w={8}
                h={"full"}
                rounded={"full"}
                border={"1px solid"}
                borderColor={"secondary.600"}
                src={playerData === null ? "" : playerData.avatar}
                sx={{
                    imageRendering: "-webkit-optimize-contrast",
                }}
            />
            <Heading
                fontSize={17}
                color={
                    selectedSession !== null &&
                    selectedSession.uniquekey === session.uniquekey
                        ? "brand.500"
                        : ""
                }
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                cursor="pointer"
                onClick={clickHandler}
                _hover={{
                    color: props.noredirect === undefined ? "brand.500" : null,
                }}
                _active={{
                    color: props.noredirect === undefined ? "brand.600" : null,
                }}
                style={{ transition: "color 150ms" }}
            >
                {nickname}
            </Heading>
            {dateFormatted !== null && (
                <Text fontSize={12}>{dateFormatted}</Text>
            )}
        </HStack>
    );
};

export default SessionPlayer;
