import { createIcon } from "@chakra-ui/icons";

const TeamSpeakLogo = createIcon({
    displayName: "TeamSpeak Logo",
    viewBox: "0 0 256 256",
    path: [
        <path
            d="M0.419223 131.225C0.838447 127.228 -6.07718e-08 122.601 0.838447 117.764C2.09612 109.981 6.49796 103.882 13.4151 100.516C15.092 99.675 15.9305 98.8337 16.3497 96.5201C19.2843 79.6936 26.2015 64.129 36.2628 50.2472C37.5205 48.5645 38.3589 47.7232 36.682 45.4096C35.0051 42.8856 36.2628 40.1513 37.9397 38.048C52.1933 22.0628 68.9622 10.2842 88.8753 3.76396C136.667 -11.3799 178.589 -1.07366 214.852 34.4723C218.206 37.8376 222.608 40.9926 218.206 46.6715C217.367 47.5129 218.625 48.3542 219.463 49.1955C229.734 63.4981 236.652 79.4832 239.796 96.7304C240.215 98.413 241.473 99.2544 242.73 100.096C251.324 104.513 255.726 111.874 255.726 121.76C255.726 130.383 256.565 139.007 255.307 147.631C253.211 160.25 240.634 168.874 228.477 166.14C225.123 165.298 223.656 162.774 223.656 159.199C223.656 144.896 224.075 130.594 223.656 116.291C222.398 77.8006 205.629 48.7749 172.301 30.2657C117.383 -0.0220011 46.1146 34.262 34.1667 96.9407C32.0706 107.668 32.4898 119.026 32.4898 129.752C32.4898 140.059 32.4898 150.155 32.0706 160.461C32.0706 163.826 29.9745 166.14 25.9918 166.14C10.271 166.981 0 157.095 0 141.11C0.419223 138.586 0.419223 135.431 0.419223 131.225Z"
            fill="white"
        />,
        <path
            d="M109.208 181.704C114.867 179.601 119.059 175.605 120.317 169.084C121.575 162.564 114.238 153.52 104.387 144.055C94.1156 134.169 80.7005 123.653 73.3641 119.867C62.6739 113.347 52.6125 119.026 50.5164 131.645C48.0011 145.527 50.5164 158.568 57.8528 170.136C63.0931 178.339 70.4295 181.915 79.4428 182.756C84.4735 182.546 104.806 183.597 109.208 181.704M155.322 185.701C161.82 186.542 167.899 187.804 174.397 188.225C182.991 188.645 189.489 186.121 194.31 180.863C200.389 174.343 203.323 166.14 202.904 157.516C202.485 148.472 195.148 143.214 185.297 144.896C176.283 146.158 168.528 150.155 160.353 153.099C153.017 156.044 146.519 159.619 141.278 164.247C133.942 171.188 137.924 180.653 149.453 184.228C150.92 184.859 153.017 185.28 155.322 185.701M223.865 177.918C222.608 176.656 220.511 177.498 219.463 179.18C215.481 191.8 197.035 234.077 134.151 238.494C59.9489 243.752 173.768 259.737 206.258 235.549C217.367 226.925 229.944 218.302 229.525 193.483C229.525 188.645 227.219 180.442 223.865 177.918"
            fill="white"
        />,
    ],
});

export default TeamSpeakLogo;
