import { ChakraProvider } from "@chakra-ui/react";
import Theme from "./components/theme/Theme";
import Index from "./pages/Index";
import Home from "./pages/Home";
import { Navigate, Route, Routes } from "react-router-dom";
import PlayerPage from "./pages/PlayerPage";
import { Helmet } from "react-helmet";
import config from "./config";
import { Flip, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { useContext, useEffect } from "react";
import AuthContext from "./contexts/AuthContext";
import ListPage from "./pages/ListPage";
import LobbyPage from "./pages/LobbyPage";
import SettingsPage from "./pages/SettingsPage";
import { SocketioContext, socketio } from "./contexts/SocketioContext";
import MapsPage from "./pages/MapsPage";
import AdminPage from "./pages/AdminPage";
import PremadePage from "./pages/PremadePage";
import TeammatesPage from "./pages/TeammatesPage";

const loader = document.querySelector(".loader");

// const showLoader = () => loader.classList.remove("loader--hide");
const hideLoader = () => loader.classList.add("loader--hide");

function App() {
    // const context = useContext(AuthContext);

    useEffect(() => {
        setTimeout(() => {
            hideLoader();

        //     toast.info("You are now using the Beta version. GL HF!", {
        //         position: "bottom-right",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         closeButton: false,
        //         transition: Flip,
        //         className: "Toastify__black-background",
        //     });
        }, config.animatedLogoTime);

        setTimeout(
            () => loader.classList.add("loader--displaynone"),
            config.animatedLogoTime + 300
        );
    }, []);

    return (
        <SocketioContext.Provider value={socketio}>
            <ChakraProvider theme={Theme}>
                <Helmet>
                    <title>{config.appName}</title>
                    <link id="favicon" rel="icon" href="/images/favicon.svg" />
                </Helmet>

                <Routes>
                    <Route path="*" element={<Navigate to="/" />} />
                    <Route path="/" element={<Index />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/list" element={<ListPage />} />
                    <Route path="/lobby" element={<LobbyPage />} />
                    <Route path="/match/:matchId" element={<MapsPage />} />
                    <Route path="/settings" element={<SettingsPage />} />
                    <Route path="/admin" element={<AdminPage />} />
                    <Route path="/premade" element={<PremadePage />} />
                    <Route path="/teammates" element={<TeammatesPage />} />
                    <Route
                        path="/player/:playerNickname"
                        element={<PlayerPage />}
                    />
                </Routes>
                <ToastContainer />
            </ChakraProvider>
        </SocketioContext.Provider>
    );
}

export default App;
