import { createIcon } from "@chakra-ui/icons";

const PortugalFlag = createIcon({
  displayName: "English Flag",
  viewBox: "0 0 512 336",
  path: [
    <path
      key="ptfl1"
      d="M473.655 335.724H38.345C17.167 335.724 0 318.557 0 297.378V38.6199C0 17.4429 17.167 0.274902 38.345 0.274902H473.655C494.832 0.274902 512 17.4419 512 38.6199V297.38C512 318.557 494.833 335.724 473.655 335.724Z"
      fill="#FF4B55"
    />,
    <path
      key="ptfl2"
      d="M38.345 0.274902C17.167 0.274902 0 17.4419 0 38.6199V297.38C0 318.557 17.167 335.725 38.345 335.725H185.379V0.274902H38.345Z"
      fill="#73AF00"
    />,
    <path
      key="ptfl3"
      d="M185.45 247.379C229.291 247.379 264.83 211.839 264.83 167.999C264.83 124.159 229.291 88.6189 185.45 88.6189C141.61 88.6189 106.07 124.159 106.07 167.999C106.07 211.839 141.61 247.379 185.45 247.379Z"
      fill="#FFE15A"
    />,
    <path
      key="ptfl4"
      d="M211.932 141.516V176.826C211.932 191.429 200.052 203.309 185.449 203.309C170.846 203.309 158.966 191.429 158.966 176.826V141.516H211.932ZM220.759 123.861H150.138C145.263 123.861 141.31 127.814 141.31 132.689V176.827C141.31 201.203 161.072 220.965 185.448 220.965C209.824 220.965 229.586 201.203 229.586 176.827V132.689C229.587 127.814 225.634 123.861 220.759 123.861Z"
      fill="#FF4B55"
    />,
    <path
      key="ptfl5"
      d="M211.932 141.516V176.826C211.932 191.429 200.052 203.309 185.449 203.309C170.846 203.309 158.966 191.429 158.966 176.826V141.516H211.932Z"
      fill="#F5F5F5"
    />,
    <path
      key="ptfl6"
      d="M150.07 137.103C152.508 137.103 154.484 135.127 154.484 132.689C154.484 130.251 152.508 128.275 150.07 128.275C147.632 128.275 145.656 130.251 145.656 132.689C145.656 135.127 147.632 137.103 150.07 137.103Z"
      fill="#FFE15A"
    />,
    <path
      key="ptfl7"
      d="M220.69 137.103C223.128 137.103 225.104 135.127 225.104 132.689C225.104 130.251 223.128 128.275 220.69 128.275C218.253 128.275 216.276 130.251 216.276 132.689C216.276 135.127 218.253 137.103 220.69 137.103Z"
      fill="#FFE15A"
    />,
    <path
      key="ptfl8"
      d="M150.07 172.413C152.508 172.413 154.484 170.437 154.484 167.999C154.484 165.561 152.508 163.585 150.07 163.585C147.632 163.585 145.656 165.561 145.656 167.999C145.656 170.437 147.632 172.413 150.07 172.413Z"
      fill="#FFE15A"
    />,
    <path
      key="ptfl9"
      d="M220.69 172.413C223.128 172.413 225.104 170.437 225.104 167.999C225.104 165.561 223.128 163.585 220.69 163.585C218.253 163.585 216.276 165.561 216.276 167.999C216.276 170.437 218.253 172.413 220.69 172.413Z"
      fill="#FFE15A"
    />,
    <path
      key="ptfl10"
      d="M185.38 137.103C187.818 137.103 189.794 135.127 189.794 132.689C189.794 130.251 187.818 128.275 185.38 128.275C182.942 128.275 180.966 130.251 180.966 132.689C180.966 135.127 182.942 137.103 185.38 137.103Z"
      fill="#FFE15A"
    />,
    <path
      key="ptfl11"
      d="M211.88 204.963C214.318 204.963 216.294 202.987 216.294 200.549C216.294 198.111 214.318 196.135 211.88 196.135C209.442 196.135 207.466 198.111 207.466 200.549C207.466 202.987 209.442 204.963 211.88 204.963Z"
      fill="#FFE15A"
    />,
    <path
      key="ptfl12"
      d="M159.4 204.963C161.838 204.963 163.814 202.987 163.814 200.549C163.814 198.111 161.838 196.135 159.4 196.135C156.963 196.135 154.986 198.111 154.986 200.549C154.986 202.987 156.963 204.963 159.4 204.963Z"
      fill="#FFE15A"
    />,
    <path
      key="ptfl13"
      d="M191.149 165.761V173.363C191.149 176.507 188.591 179.065 185.447 179.065C182.303 179.065 179.745 176.507 179.745 173.363V165.761H191.149Z"
      fill="#41479B"
    />,
    <path
      key="ptfl14"
      d="M191.149 147.74V155.342C191.149 158.486 188.591 161.044 185.447 161.044C182.303 161.044 179.745 158.486 179.745 155.342V147.74H191.149Z"
      fill="#41479B"
    />,
    <path
      key="ptfl15"
      d="M191.149 183.969V191.571C191.149 194.715 188.591 197.273 185.447 197.273C182.303 197.273 179.745 194.715 179.745 191.571V183.969H191.149Z"
      fill="#41479B"
    />,
    <path
      key="ptfl16"
      d="M206.506 165.761V173.363C206.506 176.507 203.948 179.065 200.804 179.065C197.66 179.065 195.102 176.507 195.102 173.363V165.761H206.506Z"
      fill="#41479B"
    />,
    <path
      key="ptfl17"
      d="M175.794 165.761V173.363C175.794 176.507 173.236 179.065 170.092 179.065C166.948 179.065 164.39 176.507 164.39 173.363V165.761H175.794Z"
      fill="#41479B"
    />,
  ],
});

export default PortugalFlag;
