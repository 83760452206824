// import radar from './radar.png';
const radar = require("./radar.png");

const config = {
    config: {
        origin: {
            x: -3230 + 20,
            y: 1713 + 1750,
        },
        scale: 5.0,
    },
    file: radar,
};

export default config;
