import { Text } from "@chakra-ui/react";
import { CustomBox } from "./CustomBox";

interface PropTypes {
    color: string;
    text: string;
    size?: number;
}
export function ReputationBadge(props: PropTypes) {
    return (
        <CustomBox
            bgColor={props.color}
            width="fit-content"
            py={0}
            px={"10px"}
            fontSize={props.size ?? 12}
            borderRadius={"5px"}
        >
            <Text fontWeight={"bold"} color="light.100">
                {props.text}
            </Text>
        </CustomBox>
    );
}
