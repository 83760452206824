import { HStack, Image, Tag, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import AuthContext from "../../contexts/AuthContext";
import { IMatch } from "../../types/IMatch";
import PlayerStatsType from "../../types/PlayerStatsType";
import * as Logs from "../../utils/Logs";

interface TeamPropTypes {
    teamData: { name: string; players: Array<string> };
    matchData: IMatch;
    stats: {
        data: { [K in keyof any]: PlayerStatsType };
        error: { [K in keyof any]: PlayerStatsType };
    };
    type: string;
    borderBottom?: string;
    pb?: string | number;
    mb?: string | number;
}

export function Team({ teamData, matchData, stats, type, ...props }: TeamPropTypes) {
    const context = useContext(AuthContext);
    const navigate = useNavigate();
    const playerLines = [];

    const winColor = useColorModeValue("faceit.WIN_LIGHT", "faceit.WIN");
    const lossColor = useColorModeValue("faceit.LOSS_LIGHT", "faceit.LOSS");
    const bgColor = useColorModeValue("#e5e8ea", "#101010");

    for (const id of teamData.players) {
        const partyColor =
            config.partyColors[findPlayerPartyIndex(id, matchData.parties)];
        const player = stats.data[id];
        const avatar = player.player.avatar;
        const nickname = player.player.nickname;
        const level = player.player.csgo.level;
        const elo = player.player.csgo.elo;
        const playtime = player.data.playtime ?? null;
        const matches = player.data[type].all.matches;
        const wr = Math.round(player.data[type].all.winrate);
        const wins = Math.round(player.data[type].all.wins);
        const kdr = player.data[type].all.kdr;
        const mapsData = player.data[type].maps;

        // * Player maps data
        const mapsList = [];

        for (const map of config.mapPool) {
            // const mapInfo = config.maps[map];
            mapsList.push(
                <Fragment key={mapsList.length}>
                    <Text
                        height="full"
                        width={0}
                        py="19px"
                        borderLeft={"1px solid"}
                    ></Text>
                    <Text
                        textAlign="center"
                        width={10}
                        title={mapsData[map].kdr + " KDR"}
                        whiteSpace="nowrap"
                    >
                        {mapsData[map].matches}
                    </Text>
                    <Text
                        width={10}
                        textAlign="center"
                        color={
                            mapsData[map].winrate >= 50 ? winColor : lossColor
                        }
                        title={mapsData[map].wins + " wins"}
                        whiteSpace="nowrap"
                    >
                        {Math.round(mapsData[map].winrate)}%
                    </Text>
                </Fragment>
            );
        }

        const playerClickHandler = () => {
            Logs.post(
                "click",
                {
                    type: "player",
                    value: id,
                },
                context.sessionkey
            );
            navigate("/player/" + nickname);
        };

        // * Player stats
        playerLines.push(
            <HStack key={playerLines.length}>
                <div
                    style={{
                        height: "28px",
                        border: "1px solid",
                        borderColor: partyColor,
                        width: "2px",
                    }}
                ></div>
                <Image
                    alt="Profile Avatar"
                    fit="fill"
                    fallbackSrc={config.defaultAvatar}
                    w={7}
                    h={7}
                    rounded={"full"}
                    border={"1px solid"}
                    borderColor={"secondary.400"}
                    src={avatar}
                    sx={{ imageRendering: "-webkit-optimize-contrast" }}
                />
                <Text
                    width={24}
                    cursor="pointer"
                    onClick={playerClickHandler}
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                >
                    {nickname}
                </Text>
                <div
                    title={elo + " elo"}
                    className="faceit-level"
                    style={{
                        width: "24px",
                        height: "24px",
                        backgroundImage: `url('/images/faceit_levels/${level}.svg')`,
                    }}
                ></div>
                <Text
                    width={16}
                    textAlign="center"
                    title={
                        playtime !== null
                            ? playtime["2weeks"] + "h in the last 2 weeks"
                            : "This player has a private profile."
                    }
                    whiteSpace="nowrap"
                >
                    {playtime !== null ? Math.round(playtime.all_time) : "—"}
                </Text>
                <Text width={14} textAlign="center" whiteSpace="nowrap">
                    {matches}
                </Text>
                <Text
                    width={10}
                    textAlign="center"
                    color={wr >= 50 ? winColor : lossColor}
                    title={wins + " wins"}
                    whiteSpace="nowrap"
                >
                    {wr}%
                </Text>
                <Text
                    width={10}
                    textAlign="center"
                    color={kdr > 1.05 ? winColor : kdr < 1 ? lossColor : ""}
                    whiteSpace="nowrap"
                >
                    {kdr}
                </Text>
                {mapsList}
            </HStack>
        );
    }

    // * Maps Header
    const mapsHeaderList = [];

    for (const map of config.mapPool) {
        const mapInfo = config.maps[map];
        mapsHeaderList.push(
            <Fragment key={mapsHeaderList.length}>
                <Text
                    height="full"
                    pt="15px"
                    pb="19px"
                    borderLeft={"1px solid"}
                ></Text>
                <div style={{ position: "relative" }}>
                    <Text width={"88px"} textAlign="center" fontWeight={"bold"}>
                        {mapInfo.label}
                    </Text>
                    <div
                        style={{
                            position: "absolute",
                            top: "-22px",
                            left: "-4px",
                            zIndex: "-100",
                            opacity: ".6",
                            width: "96px",
                            height: "57px",
                            boxShadow: `0 0 7px 8px ${bgColor} inset`,
                            imageRendering: "-webkit-optimize-contrast",
                            backgroundSize: "114px 57px",
                            backgroundImage: `url(${mapInfo.cover})`,
                        }}
                    ></div>
                </div>
            </Fragment>
        );
    }

    // * Stats Header
    return (
        <VStack
            spacing={0}
            borderBottom={props.borderBottom}
            pb={props.pb}
            mb={props.mb}
        >
            <Tag alignSelf={"flex-start"} mb={1}>
                {teamData.name}
            </Tag>
            <HStack>
                <div style={{ width: "2px" }}></div>
                <Text w={7}></Text>
                <Text width={24}>Nickname</Text>
                <Text width={6} textAlign="center"></Text>
                <Text width={16} textAlign="center">
                    Hours
                </Text>
                <Text width={14} textAlign="center">
                    Match
                </Text>
                <Text width={10} textAlign="center">
                    WR
                </Text>
                <Text width={10} textAlign="center">
                    KDR
                </Text>
                {mapsHeaderList}
            </HStack>
            {playerLines}
        </VStack>
    );
}

export function findPlayerPartyIndex(id: string, parties: Array<Array<string>>) {
    let i = 0;
    for (const party of parties) {
        const findInParty = party.indexOf(id);
        if (findInParty !== -1) return i;
        i++;
    }
    return -1;
}
