import {
    Alert,
    AlertIcon,
    AlertTitle,
    Flex,
    Heading,
    HStack,
    Image,
    Stack,
    Text,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import { CustomBox } from "../components/CustomBox";
import PageLayout from "../components/layout/PageLayout";
import config from "../config";
import * as Logs from "../utils/Logs";
import API from "../services/API";
import { Dispatch, useContext, useEffect, useState } from "react";
import AuthContext from "../contexts/AuthContext";
import { Flip, toast } from "react-toastify";
import PlayerType from "../types/PlayerType";
import CustomBtn from "../components/CustomBtn";
import DeleteIcon from "../components/icons/DeleteIcon";
import { CustomSpinner } from "../components/CustomSpinner";
import SearchBox from "../components/SearchBox";
import { useNavigate } from "react-router-dom";

const PremadePage = () => {
    const context = useContext(AuthContext);
    const [chosenPlayers, setChosenPlayers] = useState([]);
    const [chosenPlayersIds, setChosenPlayersIds] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [premadeData, setPremadeData] = useState(null);

    const addPlayerId = (playerId) =>
        setChosenPlayersIds([...chosenPlayersIds, playerId]);
    const removePlayerId = (playerId) =>
        setChosenPlayersIds(chosenPlayersIds.filter((e) => e !== playerId));

    useEffect(() => {
        if (context.signed) {
            setChosenPlayers([
                context.player !== null
                    ? context.player.nickname
                    : context.user.nickname,
            ]);
        }
    }, [context.signed]);

    useEffect(() => {
        window.scrollTo(0, 0);
        Logs.post("page", { page: "/premade" }, context.sessionkey);
    }, [context.sessionkey]);

    const alertBorderColor = useColorModeValue("#fbb", "#faa");
    const inputBgColor = useColorModeValue("light.300", "secondary.450");
    const inputBgColorHover = useColorModeValue("light.200", "#1e1e1e");
    const toastClass = useColorModeValue(
        "Toastify__white-background",
        "Toastify__black-background"
    );

    if (!context.signed) {
        return (
            <PageLayout py={{ base: 4, md: 8 }} pagetitle={"Premade Winrate"}>
                <VStack align={"flex-start"} spacing={3}>
                    <Heading fontSize={"3xl"} mb={2}>
                        Premade Winrate
                    </Heading>
                    <Flex
                        w={"full"}
                        justifyContent="space-between"
                        direction={{ base: "column", md: "row" }}
                        flexWrap="wrap"
                    >
                        <Alert
                            status="error"
                            borderRadius={6}
                            border="1px solid"
                            borderColor={alertBorderColor}
                        >
                            <AlertIcon />
                            <AlertTitle mr={2}>
                                You need to be logged in to access this
                                resource.
                            </AlertTitle>
                        </Alert>
                    </Flex>
                </VStack>
            </PageLayout>
        );
    }

    const chosenPlayersList = [];

    for (const player of chosenPlayers) {
        chosenPlayersList.push(
            <Player
                nickname={player}
                chosenPlayers={chosenPlayers}
                setChosenPlayers={setChosenPlayers}
                addPlayerId={addPlayerId}
                removePlayerId={removePlayerId}
                setIsLoading={setIsLoading}
                key={player}
            />
        );
    }

    const playerClickHandler = (data) => {
        const nickname = data.nickname;
        if (chosenPlayers.findIndex((p) => p === nickname) !== -1) {
            return toast.warning("Player already chosen.", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                closeButton: false,
                transition: Flip,
                className: toastClass,
            });
        }
        setChosenPlayers([...chosenPlayers, nickname]);
        setIsLoading(true);
    };

    const goClickHandler = () => {
        setIsLoading(true);
        setPremadeData({
            players: chosenPlayers,
            overall: null,
            maps: null,
        });
        API.post(config.apiEndpoints.teamMatches, { players: chosenPlayersIds })
            .then((res) => {
                const data = res.data;
                setPremadeData({
                    players: chosenPlayers,
                    overall: data.overall,
                    maps: data.maps,
                });
            })
            .catch((err) => {
                console.log(err);
                toast.error("Could not retrieve data.", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    closeButton: false,
                    transition: Flip,
                    className: toastClass,
                });
            })
            .finally(() => setIsLoading(false));
        Logs.post(
            "premadewr",
            { players: JSON.stringify(chosenPlayersIds) },
            context.sessionkey
        );
    };

    return (
        <PageLayout py={{ base: 4, md: 8 }} pagetitle={"Premade Winrate"}>
            <VStack align={"flex-start"} spacing={3}>
                <Heading fontSize={"3xl"} mb={2}>
                    Premade Winrate
                </Heading>
                <Stack
                    mt={{ base: 6, md: 12 }}
                    spacing={{ base: 0, md: 6 }}
                    direction={{ base: "column", md: "row" }}
                    w="full"
                >
                    <Flex flex={1} direction={"column"} w="full">
                        <Stack pb={4}>
                            <Text fontSize={18} fontWeight="bold">
                                Choose Players
                            </Text>
                            <SearchBox
                                onPlayerClick={(data) =>
                                    playerClickHandler(data)
                                }
                            />
                        </Stack>
                        <CustomBox py={3}>
                            <VStack alignItems={"flex-start"}>
                                {chosenPlayersList.length === 0 ? (
                                    <Text>No players selected</Text>
                                ) : (
                                    chosenPlayersList
                                )}
                            </VStack>
                        </CustomBox>
                        <CustomBtn
                            noicon
                            text="Go"
                            isLoading={isLoading}
                            onClick={goClickHandler}
                            isDisabled={
                                chosenPlayersIds.length < 2 ||
                                chosenPlayersIds.length > 5
                            }
                        />
                    </Flex>
                    <Flex
                        flex={3}
                        direction={"column"}
                        w="full"
                        pt={{ base: 5, md: 0 }}
                    >
                        <CustomBox py={3}>
                            {premadeData === null ? (
                                <Text>
                                    Choose at least 2 players and press "Go".
                                </Text>
                            ) : (
                                <PremadeDataSection data={premadeData} />
                            )}
                        </CustomBox>
                    </Flex>
                </Stack>
            </VStack>
        </PageLayout>
    );
};

function Player({
    nickname,
    chosenPlayers = null,
    setChosenPlayers = null,
    addPlayerId = null,
    removePlayerId,
    setIsLoading,
}) {
    const [playerData, setPlayerData]: [PlayerType, Dispatch<any>] =
        useState(null);

    const toastClass = useColorModeValue(
        "Toastify__white-background",
        "Toastify__black-background"
    );

    useEffect(() => {
        API.get(config.faceitProxy + config.faceitEndpoints.player + nickname)
            .then((res) => {
                const data: PlayerType = res.data.payload;
                setPlayerData(data);
                addPlayerId(data.id);
            })
            .catch((error) => {
                console.log(error);
                setChosenPlayers(chosenPlayers.filter((e) => e !== nickname));
                toast.warning("Invalid player.", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    closeButton: false,
                    transition: Flip,
                    className: toastClass,
                });
            })
            .finally(() => setIsLoading(false));
    }, []);

    const deleteClickHandler = () => {
        setChosenPlayers(chosenPlayers.filter((e) => e !== nickname));
        removePlayerId(playerData.id);
    };

    return (
        <HStack width={"full"} py={1} borderRadius={8}>
            <Image
                alt="Profile Avatar"
                fit="fill"
                fallbackSrc={config.defaultAvatar}
                w={8}
                h={8}
                rounded={"full"}
                border={"1px solid"}
                borderColor={"secondary.600"}
                src={playerData !== null ? playerData.avatar : ""}
                sx={{
                    imageRendering: "-webkit-optimize-contrast",
                }}
            />
            <Heading
                fontSize={17}
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                width={"full"}
            >
                {nickname}
            </Heading>
            <DeleteIcon
                color={"faceit.LOSS"}
                onClick={deleteClickHandler}
                cursor={"pointer"}
                fontSize={18}
            />
        </HStack>
    );
}

function PremadeDataSection({ data }) {
    const redColor = useColorModeValue("faceit.LOSS_LIGHT", "faceit.LOSS");
    const greenColor = useColorModeValue("faceit.WIN_LIGHT", "faceit.WIN");
    const greyColor = useColorModeValue("secondary.100", "light.600");

    if (data.overall === null) {
        return <CustomSpinner mt={1} alignSelf="flex-start" />;
    }

    let overallWr =
        Math.round((data.overall.wins / data.overall.matches) * 1000) / 10;
    if (isNaN(overallWr)) overallWr = 0;

    let mapsList = [];

    for (const mapData of data.maps) {
        const mapCfg = config.maps[mapData.map];
        const mapIcon = mapCfg !== undefined ? mapCfg["icon"] : "";
        const mapLabel = mapCfg !== undefined ? mapCfg["label"] : mapData.map;
        let mapWr = Math.round((mapData.wins / mapData.matches) * 1000) / 10;
        if (isNaN(mapWr)) mapWr = 0;

        mapsList.push(
            <HStack alignItems={"center"} spacing={3} key={mapsList.length}>
                <Image
                    alt="Mirage"
                    fit="fill"
                    fallbackSrc={"/images/map_icons/unknown.svg"}
                    w={10}
                    h={10}
                    borderColor={"secondary.600"}
                    src={mapIcon}
                    sx={{
                        imageRendering: "-webkit-optimize-contrast",
                    }}
                />
                <HStack alignItems={"baseline"}>
                    <Heading
                        color={mapWr < 50 ? redColor : greenColor}
                        fontSize={22}
                        title={
                            mapData.wins +
                            " win" +
                            (mapData.wins === 1 ? "" : "s")
                        }
                        cursor="help"
                    >
                        {mapWr}%
                    </Heading>
                    <Heading fontSize={20}>{mapLabel}</Heading>
                    <Heading fontSize={14} color={greyColor}>
                        ({mapData.matches} match
                        {mapData.matches === 1 ? "" : "es"})
                    </Heading>
                </HStack>
            </HStack>
        );
    }

    let playersList = [];

    for (const nick of data.players) {
        playersList.push(
            <SelectedPlayer nickname={nick} key={playersList.length} />
        );
    }

    return (
        <Stack
            mt={1}
            spacing={{ base: 0, md: 6 }}
            direction={{ base: "column", lg: "row" }}
            w="full"
        >
            <Flex flex={3} direction={"column"} w="full">
                <HStack alignItems={"baseline"}>
                    <Heading
                        color={overallWr < 50 ? redColor : greenColor}
                        fontSize={26}
                        title={
                            data.overall.wins +
                            " win" +
                            (data.overall.wins === 1 ? "" : "s")
                        }
                        cursor="help"
                    >
                        {overallWr}%
                    </Heading>
                    <Heading fontSize={23} whiteSpace="nowrap">
                        Overall Winrate
                    </Heading>
                    <Heading fontSize={16} color={greyColor}>
                        ({data.overall.matches} match
                        {data.overall.matches === 1 ? "" : "es"})
                    </Heading>
                </HStack>
                <VStack mt={5} alignItems={"flex-start"}>
                    {mapsList}
                </VStack>
            </Flex>
            <Flex flex={1} direction={"column"} w="full">
                <Heading fontSize={21} mt={{ base: 6, md: "4px" }} mb={2}>
                    Selected players
                </Heading>
                {playersList}
            </Flex>
        </Stack>
    );
}

function SelectedPlayer({ nickname }) {
    const [playerData, setPlayerData]: [PlayerType, Dispatch<any>] =
        useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        API.get(config.faceitProxy + config.faceitEndpoints.player + nickname)
            .then((res) => {
                const data: PlayerType = res.data.payload;
                setPlayerData(data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const clickHandler = () => navigate("/player/" + nickname);

    return (
        <HStack width={"full"} py={1} borderRadius={8}>
            <Image
                alt="Profile Avatar"
                fit="fill"
                fallbackSrc={config.defaultAvatar}
                w={8}
                h={8}
                rounded={"full"}
                border={"1px solid"}
                borderColor={"secondary.600"}
                src={playerData !== null && playerData.avatar}
                sx={{
                    imageRendering: "-webkit-optimize-contrast",
                }}
            />
            <Heading
                fontSize={17}
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                width={"full"}
                cursor="pointer"
                _hover={{ color: "brand.500" }}
                _active={{ color: "brand.600" }}
                style={{ transition: "color 150ms" }}
                onClick={clickHandler}
            >
                {nickname}
            </Heading>
        </HStack>
    );
}

export default PremadePage;
