import { createIcon } from "@chakra-ui/icons";

const PlusIcon = createIcon({
    displayName: "Plus Icon",
    viewBox: "0 0 15 15",
    path: [
        <path
            key="pi1"
            d="M13.9286 8.57143H8.57143V13.9286C8.57143 14.5179 8.08929 15 7.5 15C6.91071 15 6.42857 14.5179 6.42857 13.9286V8.57143H1.07143C0.482143 8.57143 0 8.08929 0 7.5C0 6.91071 0.482143 6.42857 1.07143 6.42857H6.42857V1.07143C6.42857 0.482143 6.91071 0 7.5 0C8.08929 0 8.57143 0.482143 8.57143 1.07143V6.42857H13.9286C14.5179 6.42857 15 6.91071 15 7.5C15 8.08929 14.5179 8.57143 13.9286 8.57143Z"
            fill="#EBEFF3"
        />,
    ],
});

export default PlusIcon;
