import { CheckIcon, ChevronDownIcon, ChevronRightIcon, ExternalLinkIcon, WarningTwoIcon } from "@chakra-ui/icons";
import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    Heading,
    Hide,
    HStack,
    Image,
    Input,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Show,
    Spacer,
    Stack,
    Text,
    useColorModeValue,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { Dispatch, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomBtn from "../components/CustomBtn";
import { CustomSpinner } from "../components/CustomSpinner";
import FaceitIcon from "../components/icons/FaceitIcon";
import PlusIcon from "../components/icons/PlusIcon";
import PageLayout from "../components/layout/PageLayout";
import ProfileBadge from "../components/ProfileBadge";
import config from "../config";
import API from "../services/API";
import { strftime } from "../utils/strftime";
import ReputationData from "../types/ReputationData";
import { toast, Flip } from "react-toastify";
import EloBox from "../components/EloBox";
import AuthContext from "../contexts/AuthContext";
import PlayerType from "../types/PlayerType";
import { CustomBox } from "../components/CustomBox";
import DislikeIcon from "../components/icons/DislikeIcon";
import LikeIcon from "../components/icons/LikeIcon";
import * as Logs from "../utils/Logs";
import { CommentType } from "../types/CommentType";
import CommentSection from "../components/sections/CommentSection";
import TeamSpeakLogo from "../components/icons/TeamSpeakLogo";
import MatchesGraph from "../components/MatchesGraph";

interface MapTypes {
    map: string;
    isWin?: boolean;
    matchDate: Date;
    scoreStr: string;
    matchId: string;
}

interface VoteDataTypes {
    positive_votes: number;
    negative_votes: number;
    myvote: number;
}

function PlayerPage() {
    const context = useContext(AuthContext);
    const { playerNickname } = useParams();
    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);
    const [faceitData, setFaceitData]: [PlayerType, Dispatch<any>] = useState(null);
    const [reputationData, setReputationData]: [ReputationData, Dispatch<any>] = useState(null);
    const [playerState, setPlayerState] = useState(null);
    const [teamspeakOnline, setTeamspeakOnline] = useState(false);
    const [recentMatches, setRecentMatches] = useState(null);
    const [reporterData, setReporterData]: [PlayerType, Dispatch<any>] = useState(null);
    const [voteData, setVoteData]: [VoteDataTypes, Dispatch<any>] = useState(null);
    const [comments, setComments]: [Array<CommentType>, Dispatch<any>] = useState(null);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnReportRef = useRef();

    const [reportReason, setReportReason] = useState("");
    const [reportReputation, setReportReputation] = useState("report");
    const [reload, setReload] = useState(0);

    useEffect(() => {
        // this will only be executed on prop playerNickname change
        // to clear data if this prop is changed on this page
        setLoading(true);
        setFaceitData(null);
        setReputationData(null);
        setReporterData(null);

        window.scrollTo(0, 0);

        API.get(config.faceitProxy + config.faceitEndpoints.player + playerNickname)
            .then((faceitResponse) => {
                const faceitPayload = faceitResponse.data.payload;
                // save response from faceit
                setFaceitData(faceitPayload);

                const playerId: string = faceitPayload.id;

                // get reputation data
                API.get(config.apiEndpoints.players + playerId, {
                    cache: false,
                })
                    .then((repResponse) => {
                        // save response from our api
                        const data = repResponse.data;
                        setReputationData(data);

                        if (data.reported_by !== undefined && data.reported_by.id !== null) {
                            const reporterPlayerId = data.reported_by.id;

                            API.get(config.faceitProxy + config.faceitEndpoints.user + reporterPlayerId)
                                .then((response) => {
                                    setReporterData(response.data.payload);
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    })
                    // error on faceit reputation request
                    .catch((repError) => {
                        console.error(repError.message);
                    })
                    .finally(() => setLoading(false));

                // get state data
                API.get(config.apiEndpoints.state + playerId)
                    .then((stateData) => setPlayerState(stateData.data))
                    .catch((stateError) => console.log(stateError));

                // get recent matches data
                API.get(config.apiEndpoints.recentMatches + playerId, {
                    cache: false,
                })
                    .then((matchesData) => setRecentMatches(matchesData.data.matches))
                    .catch((matchesError) => {
                        console.log(matchesError);
                        setRecentMatches(matchesError.response.data ?? null);
                    });

                // get votes data
                API.get(config.apiEndpoints.votes + playerId, { cache: false })
                    .then((res) => setVoteData(res.data.data))
                    .catch((err) => console.log(err));

                // get comments data
                API.get(config.apiEndpoints.playerComments.replace("{player_id}", playerId))
                    .then((res) => {
                        const comments = res.data.comments;
                        setComments(comments);
                    })
                    .catch((err) => {
                        console.log(err);
                        setComments([]);
                    });

                // get personal notes

                // get teamspeak online status
                API.get(config.faceitConnect.baseUrl + config.faceitConnect.endpoints.teamspeakOnline, { params: { faceitId: playerId } })
                    .then((res) => {
                        if (res.data.online) setTeamspeakOnline(true);
                    })
                    .catch((err) => console.log(err));

                // log
                Logs.post("player_page", { playerid: faceitPayload.id }, context.sessionkey);
            })
            // error on faceit api request
            .catch((faceitError) => {
                if (faceitError.response.status === 404) {
                    setFaceitData({
                        error: { code: 404, message: "Player not found." },
                    });
                    setLoading(false);
                    toast.error("This player does not exist!", {
                        position: "bottom-right",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        closeButton: false,
                        transition: Flip,
                        className: "Toastify__black-background",
                    });
                } else {
                    console.error(faceitError.message);
                }
                Logs.post("player_page", { playerid: null }, context.sessionkey);
            });
    }, [playerNickname, context.user, context.sessionkey, reload]);

    const rep = reputationData === null ? "clean" : reputationData.rep;

    const topGradientColor = useColorModeValue(`${config.reputations[rep].color}33`, `${config.reputations[rep].color}66`);
    const customBoxBgColor = useColorModeValue("light.300", "secondary.475");
    const datefrColor = useColorModeValue("secondary.100", "light.600");
    const drawerReportBgColor = useColorModeValue("light.200", "secondary.500");
    const menuListBgColor = useColorModeValue("light.75", "secondary.475");

    if (faceitData === null) {
        return (
            <PageLayout py={{ base: 6, md: 8 }} pagetitle={playerNickname}>
                <Stack mt={12} spacing={6} direction={"column"}>
                    {!isLoading || <CustomSpinner />}
                </Stack>
            </PageLayout>
        );
    }

    if (isLoading || faceitData.error !== undefined) {
        return (
            <PageLayout py={{ base: 6, md: 8 }} pagetitle={playerNickname}>
                <PlayerHeader nickname={playerNickname} faceitdata={faceitData} />
                <Stack mt={12} spacing={6} direction={"column"}>
                    {!isLoading || <CustomSpinner />}
                </Stack>
            </PageLayout>
        );
    }

    const createdDate = new Date(reputationData.createdAt ?? null);
    const formattedDate = strftime("%e %b %Y, %Hh%M", createdDate);

    const badge = reputationData.permission !== undefined && reputationData.permission === "admin" ? reputationData.permission : faceitData.verified !== undefined && faceitData.verified ? "verified" : null;

    const reportOptions = [];

    for (const [key, value] of Object.entries(config.reportOptions)) {
        reportOptions.push(<MenuItem onClick={() => setReportReputation(key)}>{value}</MenuItem>);
    }

    const reportHandler = () => {
        if (reportReason.trim().length === 0) return;
        if (reportReputation === "report") {
            API.post(config.apiEndpoints.reports + faceitData.id, {
                reason: reportReason.trim(),
                anonymous: false,
            })
                .then(() => {
                    onClose();
                    setReload(reload + 1);
                    Logs.post(
                        "report",
                        {
                            rep: reportReputation,
                            playerid: faceitData.id,
                        },
                        context.sessionkey
                    );
                })
                .catch((err) => console.log(err));
        } else {
            API.post(config.apiEndpoints.player + faceitData.id, {
                rep: reportReputation,
                reason: reportReason.trim(),
                anonymous: false,
            })
                .then(() => {
                    onClose();
                    setReload(reload + 1);
                    Logs.post(
                        "report",
                        {
                            rep: reportReputation,
                            playerid: faceitData.id,
                        },
                        context.sessionkey
                    );
                })
                .catch((err) => console.log(err));
        }
    };

    return (
        <PageLayout py={{ base: 6, md: 8 }} pagetitle={playerNickname} topgradientcolor={topGradientColor}>
            <PlayerHeader nickname={playerNickname} faceitdata={faceitData} badge={badge} />
            <Stack mt={{ base: 6, md: 12 }} spacing={{ base: 0, md: 6 }} direction={{ base: "column", md: "row" }}>
                <Flex flex={3} direction={"column"} w="full" fontSize={20}>
                    {badge === null || (
                        <Hide above="md">
                            <ProfileBadge type={badge} mb={5} width="fit-content" />
                        </Hide>
                    )}
                    <CustomBox bgColor={config.reputations[rep].color} width="fit-content">
                        <HStack>
                            {config.positiveReputations.indexOf(rep) !== -1 ? <CheckIcon color="light.100" /> : <WarningTwoIcon color="light.100" />}
                            <Text fontWeight={"bold"} color="light.100">
                                {config.reputations[rep].text}
                            </Text>
                        </HStack>
                    </CustomBox>

                    {/* <CustomBox p={4} bgColor={customBoxBgColor}>
                          <Heading fontSize={22} mb={5}>
                            Match History {playerNickname}
                          </Heading>
                          <CustomSpinner ml={4} alignSelf="auto" />
                        </CustomBox> */}
                    {reputationData.rep === "clean" || (
                        <CustomBox p={4} bgColor={customBoxBgColor}>
                            <HStack mb={3} alignItems="center">
                                {reputationData.reported_by.id !== null && (
                                    <HStack alignItems={"center"} cursor="pointer" onClick={() => navigate("/player/" + (reporterData !== null ? reporterData.nickname : reputationData.reported_by.nickname))}>
                                        <Image
                                            alt="Profile Avatar"
                                            fit="fill"
                                            fallbackSrc={config.defaultAvatar}
                                            w={6}
                                            h={"full"}
                                            rounded={"full"}
                                            border={"1px solid"}
                                            borderColor={"secondary.600"}
                                            src={reporterData !== null ? reporterData.avatar : ""}
                                            sx={{
                                                imageRendering: "-webkit-optimize-contrast",
                                            }}
                                        />
                                        <Heading fontSize={20}>{reporterData !== null ? reporterData.nickname : reputationData.reported_by.nickname}</Heading>
                                    </HStack>
                                )}
                                <Heading fontSize={12} color={datefrColor} fontStyle="italic">
                                    {formattedDate}
                                </Heading>
                            </HStack>
                            {reputationData.reason === "" || reputationData.reason === null ? (
                                <Text fontSize={18} fontStyle="italic">
                                    No reason defined.
                                </Text> // no reason
                            ) : (
                                <Text fontSize={18}>{reputationData.reason}</Text>
                            )}
                            <VoteBox mt={3} playerId={faceitData.id} voteData={voteData} setVoteData={setVoteData} sessionkey={context.sessionkey} />
                        </CustomBox>
                    )}
                    <CustomBox p={4} bgColor={customBoxBgColor}>
                        <Heading fontSize={22} mb={5}>
                            {faceitData.nickname}'s Elo
                        </Heading>
                        <EloBox playerData={faceitData} />
                    </CustomBox>
                    <CustomBox p={4} bgColor={customBoxBgColor}>
                        <Heading fontSize={22} mb={5}>
                            Matches Graph
                        </Heading>
                        <MatchesGraph playerId={faceitData.id} />
                    </CustomBox>
                    <Show above="md">
                        <CommentSection comments={comments} setComments={setComments} playerId={faceitData.id} />
                    </Show>
                </Flex>

                <Flex
                    flex={1}
                    direction={"column"}
                    w="full"
                    // pt={{ base: 7, md: 0 }}
                >
                    {playerState !== null && playerState.isInMatch && (
                        <CustomBox bgColor={"brand.500"} cursor="pointer" onClick={() => navigate("/match/" + playerState.matchId)}>
                            <HStack fontSize={18}>
                                <FaceitIcon />
                                <Text fontWeight={"bold"} mr="auto" color="light.100">
                                    Playing now!
                                </Text>
                                <Spacer />
                                <ChevronRightIcon fontSize={26} />
                            </HStack>
                        </CustomBox>
                    )}
                    {teamspeakOnline && (
                        <CustomBox bgColor={"#1C2541"} cursor="pointer" onClick={() => window.open(config.teamspeak, "_blank")}>
                            <HStack fontSize={18}>
                                <TeamSpeakLogo />
                                <Text fontWeight={"bold"} mr="auto" color="light.100">
                                    Online on TeamSpeak
                                </Text>
                                <Spacer />
                                <ChevronRightIcon fontSize={26} />
                            </HStack>
                        </CustomBox>
                    )}
                    <CustomBox py={3} bgColor={customBoxBgColor}>
                        <Text fontWeight={"bold"} fontSize={18}>
                            {context.player !== null && faceitData.id === context.player.id ? "Your recent matches" : "Recent games w/ you"}
                        </Text>
                        <VStack w="full" spacing={3} pt={3}>
                            {(recentMatches === null && <CustomSpinner />) ||
                                (recentMatches.code !== undefined && recentMatches.code === 401 && <Text alignSelf={"flex-start"}>Sign in to access this resource.</Text>) ||
                                RecentMatchesSection(recentMatches)}
                        </VStack>
                    </CustomBox>
                    <CustomBox py={3} bgColor={customBoxBgColor}>
                        <Text fontWeight={"bold"} fontSize={18}>
                            Actions
                        </Text>
                        <VStack w="full" spacing={3} pt={3} pb={2} alignItems="flex-start">
                            {context.signed && (
                                <CustomBtn height={7} fontSize={16} icon={<PlusIcon />} ref={btnReportRef} onClick={onOpen} text={config.adminPermissions.indexOf(context.user.permission) !== -1 ? "Add Reputation" : "Report"} />
                            )}

                            {/* <CustomBtn
                                height={7}
                                fontSize={16}
                                icon={<PlusIcon />}
                                text="Add to list"
                                isDisabled
                            />
                            <CustomBtn
                                height={7}
                                fontSize={16}
                                icon={<TrashIcon />}
                                text="Remove from a list"
                                isDisabled
                            /> */}
                        </VStack>
                    </CustomBox>
                    <Hide above="md">
                        <CommentSection comments={comments} setComments={setComments} playerId={faceitData.id} />
                    </Hide>
                </Flex>
            </Stack>
            <Drawer isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={btnReportRef}>
                <DrawerOverlay />
                <DrawerContent bgColor={drawerReportBgColor}>
                    <DrawerCloseButton />
                    <DrawerHeader>Report {faceitData === null ? playerNickname : faceitData.nickname}</DrawerHeader>

                    <DrawerBody>
                        <Input
                            _focus={{
                                outlineColor: "brand.300",
                                transition: "100ms ease",
                            }}
                            placeholder="Type here your reason..."
                            marginBottom={4}
                            onChange={(e) => setReportReason(e.target.value)}
                            value={reportReason}
                        />
                        <Menu>
                            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                {config.reportOptions[reportReputation]}
                            </MenuButton>
                            <MenuList bgColor={menuListBgColor}>{reportOptions}</MenuList>
                        </Menu>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme="brand" onClick={reportHandler}>
                            Save
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </PageLayout>
    );
}

export function PlayerHeader({ nickname, faceitdata, badge = null }) {
    const context = useContext(AuthContext);
    const goToFaceitProfileLabel = "Open " + nickname + "'s FACEIT Profile";
    return (
        <>
            <Box pos="relative" h={{ base: 48, md: 52 }} overflow={"hidden"}>
                <Image alt="Profile Background" fit="cover" fallbackSrc="/images/fallback_bg.png" w="full" h={{ base: 36, md: 40 }} rounded="lg" src={faceitdata === null || (faceitdata.cover_image_url ?? "")} />
                <HStack pos={"absolute"} h={32} bottom={0} left={{ base: 4, md: 6 }} alignItems={"flex-end"} spacing={{ base: 3, md: 6 }}>
                    <Image
                        alt="Profile Avatar"
                        fit="fill"
                        fallbackSrc={config.defaultAvatar}
                        w={{ base: 24, md: 32 }}
                        h={{ base: 24, md: 32 }}
                        rounded={"full"}
                        border={"1px solid"}
                        borderColor={"secondary.400"}
                        src={faceitdata === null || (faceitdata.avatar ?? "")}
                        sx={{ imageRendering: "-webkit-optimize-contrast" }}
                    />
                    <HStack spacing={5} alignItems="center">
                        <HStack spacing={2}>
                            <Heading fontSize={"1.75em"}>{nickname}</Heading>
                            <Link
                                href={config.faceitPlayerProfileUrl + nickname}
                                target={"_blank"}
                                borderRadius={6}
                                w="fit-content"
                                onClick={() =>
                                    Logs.post(
                                        "click",
                                        {
                                            type: "btn",
                                            value: goToFaceitProfileLabel,
                                        },
                                        context.sessionkey
                                    )
                                }
                                title={goToFaceitProfileLabel}
                            >
                                <ExternalLinkIcon _hover={{ color: "brand.500" }} _active={{ color: "brand.600" }} style={{ transition: "color 150ms" }} px={1} w="full" />
                            </Link>
                        </HStack>

                        {badge === null || (
                            <Show above="md">
                                <ProfileBadge type={badge} />
                            </Show>
                        )}
                    </HStack>
                </HStack>
            </Box>
        </>
    );
}

export function RecentMatchesSection(data) {
    const maps = [];

    if (!data.length) return <Text alignSelf={"flex-start"}>No recent matches.</Text>;

    let i = 0;
    for (const match of data) {
        const isWin = match.playerFaction === match.winner;
        const date = new Date(match.finishedAt);
        maps.push(<Map map={match.map} isWin={isWin} matchDate={date} scoreStr={match.score} matchId={match.id} key={i++} />);
    }

    return maps;
}

export function VoteBox({ mt, playerId, voteData, setVoteData, sessionkey }: { mt: number; playerId: string; voteData: VoteDataTypes; setVoteData: Dispatch<any>; sessionkey: string }) {
    const postVote = (type: number) => {
        API.post(config.apiEndpoints.votes + playerId, { vote: type })
            .then((res) => {
                setVoteData(res.data.data);
                toast.success(`${res.data.status.vote === 1 ? "Like" : "Dislike"} ${["update", "add"].indexOf(res.data.status.action) !== -1 ? "added" : "removed"} successfully.`, {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    closeButton: false,
                    transition: Flip,
                    className: "Toastify__black-background",
                });
                Logs.post(
                    "critic",
                    {
                        playerid: playerId,
                        action: res.data.status.action,
                        type: type,
                    },
                    sessionkey
                );
            })
            .catch((err) => console.log(err));
    };

    return (
        <HStack mt={mt ?? 0} spacing={4} color={useColorModeValue("secondary.300", "light.400")}>
            <HStack cursor="pointer" color={voteData !== null && voteData.myvote === 1 ? "brand.500" : ""} transition="ease-in-out 150ms" onClick={() => postVote(1)}>
                <LikeIcon />
                <Text fontSize={12} fontWeight="bold">
                    {voteData !== null ? voteData.positive_votes : "-"}
                </Text>
            </HStack>
            <HStack cursor="pointer" color={voteData !== null && voteData.myvote === 0 ? "brand.500" : ""} transition="ease-in-out 150ms" onClick={() => postVote(0)}>
                <DislikeIcon />
                <Text fontSize={12} fontWeight="bold">
                    {voteData !== null ? voteData.negative_votes : "-"}
                </Text>
            </HStack>
        </HStack>
    );
}

export function Map({ map, isWin = false, matchDate, scoreStr, matchId }: MapTypes) {
    const navigate = useNavigate();
    const mapCfg = config.maps[map];
    const result = isWin ? "WIN" : "LOSS";

    const now = new Date();
    const nowStr = now.toLocaleDateString().valueOf();
    now.setDate(now.getDate() - 1);
    const yesterdayStr = now.toLocaleDateString().valueOf();
    const matchDateStr = matchDate.toLocaleDateString("en-GB").valueOf();
    const score = scoreStr.split(" / ");

    const dateStr = nowStr === matchDateStr ? "Today" : yesterdayStr === matchDateStr ? "Yesterday" : strftime("%a %e %b %Y", matchDate);

    return (
        <HStack w={"full"} spacing={3} onClick={() => navigate("/match/" + matchId)} cursor="pointer">
            <Box>
                <Image
                    alt={mapCfg === undefined ? map : mapCfg.label}
                    title={mapCfg === undefined ? map : mapCfg.label}
                    fit="fill"
                    // fallbackSrc=""
                    w={10}
                    src={mapCfg === undefined ? "/images/map_icons/unknown.svg" : mapCfg.icon}
                    sx={{ imageRendering: "-webkit-optimize-contrast" }}
                />
            </Box>
            <VStack spacing={0} align="left">
                <HStack alignItems={"end"}>
                    <Text color={useColorModeValue("faceit." + result + "_LIGHT", "faceit." + result)} fontWeight="bold" h={5}>
                        {result}
                    </Text>
                    <Text fontSize={9}>{score[0] + "-" + score[1]}</Text>
                </HStack>
                <Text fontSize={12}>
                    {dateStr} • {strftime("%Hh%M", matchDate)}
                </Text>
            </VStack>
        </HStack>
    );
}

export default PlayerPage;
