import { ExternalLinkIcon, InfoIcon } from "@chakra-ui/icons";
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Alert,
    AlertIcon,
    AlertTitle,
    Badge,
    Box,
    Checkbox,
    Divider,
    Heading,
    Hide,
    HStack,
    Image,
    Link,
    Spacer,
    Stack,
    Text,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import { Dispatch, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactSwitch from "react-switch";
import { Flip, toast } from "react-toastify";
import { CustomBox } from "../components/CustomBox";
import CustomBtn from "../components/CustomBtn";
import { CustomSpinner } from "../components/CustomSpinner";
import PageLayout from "../components/layout/PageLayout";
import { Maps } from "../components/maps_page/Maps";
import { findPlayerPartyIndex } from "../components/maps_page/Team";
import Radar, { utilityTypes } from "../components/Radar/Radar";
import { StatusDot } from "../components/StatusDot";
import config from "../config";
import AuthContext from "../contexts/AuthContext";
import API from "../services/API";
import { IMatch } from "../types/IMatch";
import { IUtilityPos } from "../types/IUtilityPos";
import * as Logs from "../utils/Logs";

const MapsPage = () => {
    const context = useContext(AuthContext);
    const { matchId } = useParams();

    const [matchData, setMatchData]: [IMatch, Dispatch<any>] = useState(null);
    const [stats, setStats] = useState(null);
    const [viewType, setViewType] = useState(config.defaultMapsStatsViewType);
    const [isLoading, setIsLoading] = useState(true);
    const [match, setMatch] = useState(null);
    const [pistolRoundData, setPistolRoundData] = useState(null);
    const [isLoadingPistolRound, setIsLoadingPistolRound] = useState(false);
    const [utilitySide, setUtilitySide] = useState("t");
    const [pistolRoundPlayers, setPistolRoundPlayers] = useState([]);

    useEffect(() => {
        // window.scroll(0, 0);

        if (!context.signed) return;

        if (matchId === "current") {
            // get state from current player
            API.get(config.apiEndpoints.state + context.user.id, {
                cache: false,
            })
                .then((res) => {
                    setMatchData(res.data);
                    Logs.post(
                        "maps",
                        {
                            matchid: res.data.isInMatch ? res.data.matchId : null,
                            current: 1,
                        },
                        context.sessionkey
                    );
                    if (!res.data.isInMatch) return setIsLoading(false);
                    getAllPlayersStats(res.data);
                    getMatch(res.data.matchId);
                })
                .catch((err) => console.log(err));
        } else {
            // get match data
            API.get(config.apiEndpoints.match + matchId)
                .then((res) => {
                    setMatchData(res.data);
                    getAllPlayersStats(res.data);
                    getMatch(matchId);
                    Logs.post(
                        "maps",
                        {
                            matchid: matchId,
                            current: 0,
                        },
                        context.sessionkey
                    );
                })
                .catch((err) => {
                    setIsLoading(false);
                    console.log(err);
                });
        }
    }, [context.signed, matchId, context.sessionkey]);

    const getEnemyFaction = () => {
        if (matchId === "current") return matchData.playerFaction === "faction1" ? "faction2" : "faction1";
        if (!context.signed) return null;

        for (const [key, value] of Object.entries(matchData.factions)) for (const player of value.players) if (player === context.user.id) return key === "faction1" ? "faction2" : "faction1";

        return null;
    };

    const getFactionParties = (enemyFaction) => {
        const factionParties = { faction1: [], faction2: [] };
        for (const party of matchData.parties) {
            // for each party check if a player is in the enemy faction
            if (matchData.factions[enemyFaction].players.includes(party[0])) factionParties[enemyFaction].push(party);
            else factionParties[enemyFaction === "faction1" ? "faction2" : "faction1"].push(party);
        }
        return factionParties;
    };

    useEffect(() => {
        if (match !== null && match.error === undefined) {
            const enemyFaction = getEnemyFaction();
            if (enemyFaction !== null) {
                const factionParties = getFactionParties(enemyFaction);
                let players = matchData.factions[enemyFaction as any].players;

                for (const party of factionParties[enemyFaction]) {
                    if (party.length >= 3) players = party;
                }

                setPistolRoundPlayers(players);
            }
        }
    }, [match]);

    function getMatch(matchId) {
        API.get(config.faceitProxy + config.faceitEndpoints.match + matchId, {
            cache: false,
        })
            .then((res) => {
                setMatch(res.data.payload);
                const stateCfg = config.matchStates[res.data.payload.state];
                // TODO: use faceit edge websockets instead of polling
                //       check the google doc for more info about the events
                if (stateCfg.delay !== 0) setTimeout(() => getMatch(matchId), stateCfg.delay);
            })
            .catch((err) => {
                console.log(err);
                setMatch({ error: err });
            });
    }

    function getAllPlayersStats(match: IMatch) {
        const promises = [];
        for (const [, faction] of Object.entries(match.factions)) {
            for (const id of faction.players) {
                promises.push(API.get(config.apiEndpoints.playerStats + id));
            }
        }

        const data = {};
        const errors = {};

        Promise.allSettled(promises)
            .then((results) => {
                results.forEach((result) => {
                    if (result.status === "rejected") {
                        const resultError = result.reason.response.data;
                        errors[resultError.playerId] = resultError;
                        return;
                    }

                    const resultData = result.value.data;
                    data[resultData.playerId] = resultData;
                });
            })
            .then(() => {
                setStats({
                    data: data,
                    errors: errors,
                });
            })
            .then(() => setIsLoading(false))
            .catch((err) => {
                console.log(err);
            });
    }

    const alertBorderColor = useColorModeValue("#fbb", "#faa");

    if (!context.signed) {
        return (
            <PageLayout py={{ base: 4, md: 8 }} pagetitle={"Check Maps"}>
                <VStack align={"flex-start"} spacing={3}>
                    <Heading fontSize={"3xl"} mb={1}>
                        Check maps
                    </Heading>
                    <Alert status="error" borderRadius={6} border="1px solid" borderColor={alertBorderColor}>
                        <AlertIcon />
                        <AlertTitle mr={2}>You need to be signed in to access this resource.</AlertTitle>
                    </Alert>
                </VStack>
            </PageLayout>
        );
    }

    if (isLoading) {
        return (
            <PageLayout py={{ base: 4, md: 8 }} pagetitle={"Check Maps"}>
                <VStack align={"flex-start"} spacing={3}>
                    <HStack spacing={5}>
                        <Heading fontSize={"3xl"} mb={1}>
                            Check maps
                        </Heading>
                        <CustomSpinner alignSelf="flex-start" />
                    </HStack>
                </VStack>
            </PageLayout>
        );
    }

    if (matchData === null) {
        return (
            <PageLayout py={{ base: 4, md: 8 }} pagetitle={"Check Maps"}>
                <VStack align={"flex-start"} spacing={3}>
                    <Heading fontSize={"3xl"} mb={1}>
                        Check maps
                    </Heading>
                    <Alert status="error" borderRadius={6} border="1px solid" borderColor={alertBorderColor}>
                        <AlertIcon />
                        <AlertTitle mr={2}>There was an error while retrieving match data.</AlertTitle>
                    </Alert>
                </VStack>
            </PageLayout>
        );
    }

    const showLastMatchesChangeHandler = (to) => {
        setViewType(viewType === "last_matches_stats" ? "all_time_stats" : "last_matches_stats");
        Logs.post(
            "maps_viewtype",
            {
                key: "Show only last 100 matches",
                value: to ? 1 : 0,
            },
            context.sessionkey
        );
    };

    const utilitySideHandler = (to) => {
        setUtilitySide(to ? "ct" : "t");
        Logs.post(
            "maps_viewtype",
            {
                key: "Utility Pos Side - OFF: T / ON: CT",
                value: to ? 1 : 0,
            },
            context.sessionkey
        );
    };

    const getPistolRoundDataHandler = () => {
        const enemyFaction = getEnemyFaction();
        if (enemyFaction === null) return;

        setIsLoadingPistolRound(true);

        API.post(config.apiEndpoints.premadeMapMatches, {
            players: pistolRoundPlayers,
            map: match.voting.map.pick[0],
        })
            .then((res) => {
                const data = res.data.data;
                parseDemos(data);
                Logs.post("pistolRoundData", { matchid: matchData.matchId, current: 0 }, context.sessionkey);
            })
            .catch((err) => {
                console.log(err);
                setIsLoadingPistolRound(false);
                toast.error("Error while parsing data.", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    closeButton: false,
                    transition: Flip,
                    className: "Toastify__black-background",
                });
            });
    };

    const parseDemos = (data) => {
        // create an array of promises
        // for each object in the data array, add a promise to the array
        // where the promise is a call to the API to get the demo data
        const promises = [];

        const enemyFaction = getEnemyFaction();

        const enemyIndex = matchData.factions[enemyFaction as any].players.indexOf(pistolRoundPlayers[0]);
        console.log(enemyIndex);

        const enemyId64: String = stats.data[matchData.factions[enemyFaction as any].players[enemyIndex]].player.steam_id_64;

        for (const demo of data) {
            // add a promise to the array
            promises.push(
                API.get(config.demoParserApi + demo.id, {
                    params: { enemyId: enemyId64 },
                })
            );
        }

        // wait for all promises to resolve
        // then set the data to the parsed data
        // and set the isLoadingPistolRound to false
        Promise.all(promises)
            .then((results) => {
                const parsedData = [];
                for (const result of results) {
                    parsedData.push(result.data.data);
                }
                setPistolRoundData(parsedData);
            })
            .catch((err) => {
                console.log(err);
                toast.warning("Please try again in 10 seconds (it will take less time).", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    closeButton: false,
                    transition: Flip,
                    className: "Toastify__black-background",
                });
            })
            .finally(() => setIsLoadingPistolRound(false));
    };

    const stateCfg = match !== null ? config.matchStates[match.state] : null;
    const enemyFaction = getEnemyFaction();

    return (
        <PageLayout py={{ base: 4, md: 8 }} pagetitle={"Check Maps"}>
            <VStack align={"flex-start"} spacing={3}>
                <Heading fontSize={"3xl"} mb={1}>
                    Check maps
                </Heading>
                {(matchId === "current" && !matchData.isInMatch && (
                    <Alert status="error" borderRadius={6} border="1px solid" borderColor={alertBorderColor}>
                        <AlertIcon />
                        <AlertTitle mr={2}>You're not in a match.</AlertTitle>
                    </Alert>
                )) || (
                    <>
                        <Box w="full">
                            <HStack mb={1}>
                                <Text>Show only last 100 matches</Text>
                                <ReactSwitch
                                    checked={viewType === "last_matches_stats"}
                                    onChange={showLastMatchesChangeHandler}
                                    onColor="#ff9966"
                                    onHandleColor="#FF5500"
                                    handleDiameter={18}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(255, 85, 0, 0.2)"
                                    height={10}
                                    width={28}
                                />
                                <Spacer />
                                <Link
                                    href={config.faceitMatchRoomUrl + matchData.matchId}
                                    isExternal
                                    borderRadius={5}
                                    px={3}
                                    onClick={() => {
                                        Logs.post(
                                            "click",
                                            {
                                                type: "btn",
                                                value: "Go to match room",
                                            },
                                            context.sessionkey
                                        );
                                    }}
                                >
                                    Go to match room <ExternalLinkIcon mx="2px" />
                                </Link>
                            </HStack>
                        </Box>
                        <Maps matchData={matchData} stats={stats} matchId={matchId} viewType={viewType} />
                    </>
                )}
                {match !== null && match.error === undefined && (
                    <>
                        <HStack>
                            <StatusDot blinking={stateCfg.blinking} color={stateCfg.color} />
                            <Text fontWeight={"bold"}>{stateCfg.text}</Text>
                            {stateCfg.isPick && <Text>Map: {config.maps[match.voting.map.pick[0]] === undefined ? match.voting.map.pick[0] : config.maps[match.voting.map.pick[0]].label}</Text>}
                        </HStack>
                        <Accordion w={"full"} allowToggle>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                        <HStack flex="1" textAlign="left">
                                            <InfoIcon />
                                            <Badge>BETA</Badge>
                                            <Text>Predict pistol round</Text>
                                        </HStack>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <VStack alignItems="flex-start">
                                        <Heading size={"md"}>What is this?</Heading>
                                        <Text>
                                            This is a feature that allows players to predict where the enemy will plant the bomb, based on recent games. {config.appName} takes the enemy's last matches on the voted map, analyzes the demos
                                            and returns information such as where the bomb was planted and the utility purchased & where it was used on each side (T and CT) in pistol rounds.
                                        </Text>
                                        <Text>
                                            <b>Important note:</b> this information is based on public data available on the FACEIT website. You should not rely solely on these data, as each match is unique.
                                        </Text>
                                        {/* <Text>
                                            <Badge>Update</Badge> 13/04/2022 - Now you can see the <b>position of the utility thrown by the enemy</b> on the T side pistol round! & Improvements in the demo parsing.
                                        </Text>
                                        <Text>
                                            <Badge>Update</Badge> 14/04/2022 - Ability to select the players to be used for the prediction; utility position also on CT side.
                                        </Text> */}
                                        <HStack wrap={"wrap"}>
                                            {/* <Text fontWeight={"bold"}>Legend: </Text> */}
                                            {utility(utilityTypes.smoke)}
                                            <Text>Smoke&nbsp;</Text>
                                            {utility(utilityTypes.flashbang)}
                                            <Text>Flashbang&nbsp;</Text>
                                            {utility(utilityTypes.explosive)}
                                            <Text>Grenade&nbsp;</Text>
                                            {utility(utilityTypes.molotov)}
                                            <Text>Molotov/Incendiary</Text>
                                        </HStack>

                                        <Stack
                                            alignItems={"flex-start"}
                                            flexDir={{
                                                base: "column",
                                                md: "row",
                                            }}
                                            spacing={{ base: 3, md: 0 }}
                                            justifyContent={"space-between"}
                                            w="full"
                                        >
                                            <VStack alignItems={"flex-start"}>
                                                <HStack>
                                                    <Text>Utility Pos Side - T</Text>
                                                    <ReactSwitch
                                                        checked={utilitySide === "ct"}
                                                        onChange={utilitySideHandler}
                                                        onColor="#ff9966"
                                                        onHandleColor="#FF5500"
                                                        handleDiameter={18}
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                        activeBoxShadow="0px 0px 1px 10px rgba(255, 85, 0, 0.2)"
                                                        height={10}
                                                        width={28}
                                                    />
                                                    <Text>CT&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>
                                                </HStack>
                                                <CustomBtn
                                                    noicon
                                                    isDisabled={!stateCfg.pistolRoundData || enemyFaction === null}
                                                    isLoading={isLoadingPistolRound}
                                                    onClick={getPistolRoundDataHandler}
                                                    fontSize={16}
                                                    height={7}
                                                    text="Get data"
                                                    title={matchId === "current" ? "You are not currently playing in this match." : stateCfg.message}
                                                />
                                            </VStack>

                                            <CustomBox width="fit-content">
                                                <HStack flexWrap="wrap">
                                                    {enemyFaction !== null &&
                                                        matchData.factions[enemyFaction].players.map((pid) => {
                                                            const partyColor = config.partyColors[findPlayerPartyIndex(pid, matchData.parties)];
                                                            const player = stats.data[pid];
                                                            const avatar = player.player.avatar;
                                                            const nickname = player.player.nickname;

                                                            const pistolRoundPlayerClickHandler = () => {
                                                                if (pistolRoundPlayers.indexOf(pid) !== -1) {
                                                                    setPistolRoundPlayers(pistolRoundPlayers.filter((p) => p !== pid));
                                                                    return;
                                                                }
                                                                setPistolRoundPlayers([...pistolRoundPlayers, pid]);
                                                            };

                                                            return (
                                                                <VStack justifyContent={"center"} textAlign="center" spacing={1}>
                                                                    <div
                                                                        style={{
                                                                            width: "40px",
                                                                            border: "1px solid",
                                                                            borderColor: partyColor,
                                                                            height: "2px",
                                                                        }}
                                                                    ></div>
                                                                    <Image
                                                                        alt="Profile Avatar"
                                                                        fit="fill"
                                                                        fallbackSrc={config.defaultAvatar}
                                                                        w={7}
                                                                        h={7}
                                                                        rounded={"full"}
                                                                        border={"1px solid"}
                                                                        borderColor={"secondary.400"}
                                                                        src={avatar}
                                                                        sx={{
                                                                            imageRendering: "-webkit-optimize-contrast",
                                                                        }}
                                                                    />
                                                                    <Text textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" width={20}>
                                                                        {nickname}
                                                                    </Text>
                                                                    <Checkbox colorScheme={"blue"} isDisabled={isLoadingPistolRound} isChecked={pistolRoundPlayers.indexOf(pid) !== -1} onChange={pistolRoundPlayerClickHandler} />
                                                                </VStack>
                                                            );
                                                        })}
                                                </HStack>
                                            </CustomBox>
                                        </Stack>

                                        {pistolRoundData !== null && <PistolRoundsData data={pistolRoundData} utilitySide={utilitySide} />}
                                    </VStack>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </>
                )}
            </VStack>
        </PageLayout>
    );
};

const utility = (color) => {
    return (
        <div
            style={{
                padding: "7px",
                background: color,
                borderRadius: "50%",
                border: "1px solid #222",
            }}
        ></div>
    );
};

interface IRoundData {
    bombsite: String;
    buys: {
        ct: Array<String>;
        t: Array<String>;
    };
    map: string;
    utility_pos: {
        t: IUtilityPos[];
        ct: IUtilityPos[];
    };
}

function PistolRoundsData({ data, utilitySide }: { data: Array<IRoundData>; utilitySide: string }) {
    if (!data.length) return <Text>The enemy team never played together in this map.</Text>;
    let i = 0;

    const results = [];

    for (const el of data) {
        const buys = {
            ct: {},
            t: {},
        };

        for (const [key, value] of Object.entries(el.buys)) {
            for (const item of value) {
                if (buys[key][item] === undefined) buys[key][item] = 1;
                else buys[key][item]++;
            }
        }

        results.push(
            <>
                <Stack alignItems={"center"} flexDirection={{ base: "column", xl: "row" }} justifyContent={"space-between"} w="full" flexWrap="wrap">
                    <Heading size={"md"}>Match {++i}</Heading>
                    <Text>
                        Bombsite: <b>{el.bombsite === null ? "Didn't plant" : el.bombsite === "BombsiteA" ? "A" : el.bombsite === "BombsiteB" ? "B" : el.bombsite}</b>
                    </Text>
                    <HStack spacing={8}>
                        <VStack>
                            <Text fontWeight={"bold"}>T Utility</Text>
                            {Object.entries(buys.t).map(([key, value]) => (
                                <Text>
                                    {value}x {key}
                                </Text>
                            ))}
                        </VStack>
                        <VStack>
                            <Text fontWeight={"bold"}>CT Utility</Text>
                            {Object.entries(buys.ct).map(([key, value]) => (
                                <Text>
                                    {value}x {key}
                                </Text>
                            ))}
                        </VStack>
                    </HStack>
                    <Hide below="xl">
                        <Radar map={el.map} utility={el.utility_pos[utilitySide]} />
                    </Hide>
                </Stack>
                {results.length !== data.length - 1 && <Divider />}
            </>
        );
    }

    return (
        <VStack w="full" alignItems="flex-start" justifyContent={"space-around"}>
            {results}
        </VStack>
    );
}

export default MapsPage;
