import { createIcon } from "@chakra-ui/icons";

const ListIcon = createIcon({
    displayName: "List Icon",
    viewBox: "0 0 128 75",
    path: [
        <path
            d="M102.4 74.468H128C128 74.468 128 37.2314 90.7637 48.8678C103.564 59.3405 102.4 74.468 102.4 74.468Z"
            // fill="#EBEFF3"
            fill="currentColor"
            key="lic1"
        />,
        <circle
            cx="103.564"
            cy="26.7637"
            r="15.1273"
            fill="currentColor"
            key="lic2"
        />,
        <circle
            cx="64.0005"
            cy="18.6182"
            r="18.6182"
            fill="currentColor"
            key="lic3"
        />,
        <path
            d="M96.5819 74.4728H31.4182C31.4182 74.4728 33.7455 41.8909 64 41.8909C94.2546 41.8909 96.5819 74.4728 96.5819 74.4728Z"
            fill="currentColor"
            key="lic4"
        />,
        <path
            d="M25.6002 74.4728H0C0 74.4728 -3.55114e-05 37.2362 37.2363 48.8727C24.4362 59.3453 25.6002 74.4728 25.6002 74.4728Z"
            fill="currentColor"
            key="lic5"
        />,
        <circle
            cx="24.4361"
            cy="26.7637"
            r="15.1273"
            fill="currentColor"
            key="lic6"
        />,
    ],
});

export default ListIcon;
