import {
    Divider,
    Flex,
    Heading,
    HStack,
    Image,
    Stack,
    Text,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import { CustomBox } from "../components/CustomBox";
import PageLayout from "../components/layout/PageLayout";
import { Dispatch, useContext, useEffect, useState } from "react";
import config from "../config";
import AuthContext from "../contexts/AuthContext";
import { Navigate, useNavigate } from "react-router-dom";
import * as Logs from "../utils/Logs";
import { SocketioContext } from "../contexts/SocketioContext";
import API from "../services/API";
import { strftime } from "../utils/strftime";
import { CustomSpinner } from "../components/CustomSpinner";
import eventConfig from "../eventConfig";
import Player from "../components/logs/Player";
import Match from "../components/logs/Match";
import Profile from "../components/logs/Profile";
import Options from "../components/logs/Options";
import Btn from "../components/logs/Btn";
import Replace from "../components/logs/Replace";
import SessionPlayer from "../components/logs/SessionPlayer";
import PlayerType from "../types/PlayerType";
import PremadeWr from "../components/logs/PremadeWr";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { StatusDot } from "../components/StatusDot";

const AdminPage = () => {
    const context = useContext(AuthContext);
    const socketio = useContext(SocketioContext);

    const [onlineCount, setOnlineCount] = useState(0);
    const [onlineUsers, setOnlineUsers] = useState(null);
    const [recentSessions, setRecentSessions] = useState(null);
    const [selectedSession, setSelectedSession] = useState(null);
    const [selectedSessionLogs, setSelectedSessionLogs] = useState(null);
    const [retry, setRetry] = useState([]);
    // const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        Logs.post("page", { page: "/admin" }, context.sessionkey);

        getRecentSessions();

        socketio.emit("subscribe/admin");
        socketio.on("update_online", (data) => setOnlineCount(data.online));
        socketio.on("online_users", (data) => {
            setOnlineUsers(data);
            getRecentSessions();
        });

        return () => {
            socketio.off("online_users");
            socketio.off("update_online");
            socketio.emit("unsubscribe/admin");
        };
    }, [context.sessionkey]);

    function getRecentSessions() {
        API.get(config.apiEndpoints.sessions, { cache: false })
            .then((res) => setRecentSessions(res.data))
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        const source = axios.CancelToken.source();
        setSelectedSessionLogs(null);
        // const reqid = selectedSession !== null ? selectedSession.uniquekey : "";

        if (selectedSession !== null) {
            const uniquekey = selectedSession.uniquekey;
            // const checkRetry = retry.indexOf(uniquekey) !== -1;

            API.get(config.apiEndpoints.sessions + uniquekey, {
                cancelToken: source.token,
                // cache: checkRetry ? false : undefined,
                cache: false,
            })
                .then(async (res) => {
                    setSelectedSessionLogs(res.data.logs);
                })
                .catch((err) => {
                    console.log(err);
                    if (axios.isCancel(err))
                        setRetry(retry.concat([uniquekey]));
                });
        }
        return () => source.cancel();
    }, [selectedSession]);

    if (
        !context.signed ||
        // config.adminPermissions.indexOf(context.user.permission) === -1
        context.user.permission !== "superadmin"
    ) {
        return <Navigate to={"/home"}></Navigate>;
    }

    return (
        <PageLayout py={{ base: 4, md: 8 }} pagetitle="Admin Dashboard">
            <VStack align={"flex-start"} spacing={3}>
                <Heading fontSize={"3xl"} mb={1}>
                    Admin Dashboard
                </Heading>
                <Stack
                    mt={{ base: 6, md: 12 }}
                    spacing={{ base: 0, md: 6 }}
                    direction={{ base: "column", md: "row" }}
                    w="full"
                >
                    <Flex flex={1} direction={"column"} w="full">
                        <CustomBox py={3}>
                            <VStack alignItems={"flex-start"}>
                                <HStack>
                                    <StatusDot />
                                    <Text fontWeight={"bold"} fontSize={18}>
                                        Online Users
                                    </Text>
                                    <Text>{onlineCount}</Text>
                                </HStack>
                                <OnlineUsers onlineData={onlineUsers} />
                            </VStack>
                        </CustomBox>
                        <CustomBox py={3} px={2}>
                            <VStack alignItems={"flex-start"} spacing={1}>
                                <Text fontWeight={"bold"} fontSize={18} px={2}>
                                    Today's recent sessions
                                </Text>
                                <RecentSessions
                                    sessionsData={recentSessions}
                                    selectedSession={selectedSession}
                                    setSelectedSession={setSelectedSession}
                                />
                            </VStack>
                        </CustomBox>
                        <CustomBox py={3}>
                            <VStack alignItems={"flex-start"} spacing={1}>
                                <Text fontWeight={"bold"} fontSize={18}>
                                    Today's website views
                                </Text>
                                <Text fontSize={15}>
                                    {recentSessions === null
                                        ? "—"
                                        : recentSessions.views}
                                </Text>
                            </VStack>
                        </CustomBox>
                        <CustomBox py={3}>
                            <VStack alignItems={"flex-start"} spacing={1}>
                                <Text fontWeight={"bold"} fontSize={18}>
                                    Today's unique visitors
                                </Text>
                                <Text fontSize={15}>
                                    {recentSessions === null
                                        ? "—"
                                        : recentSessions.uniqueVisitors}
                                </Text>
                            </VStack>
                        </CustomBox>
                    </Flex>
                    <Flex flex={3} direction={"column"} w="full">
                        <CustomBox py={3}>
                            {(selectedSession == null && (
                                <Text>
                                    Select a session to views its details.
                                </Text>
                            )) || (
                                <SessionLogs
                                    selectedSession={selectedSession}
                                    logs={selectedSessionLogs}
                                />
                            )}
                        </CustomBox>
                    </Flex>
                </Stack>
            </VStack>
        </PageLayout>
    );
};

interface LogType {
    at: string;
    data_key: string;
    data_tinyint: number;
    data_value: string;
    event: string;
}

function EventLog({ logData }: { logData: LogType }) {
    // possible types: player match profile replace options btn
    let result = [];
    const thisEventCfg = eventConfig[logData.event];
    if (thisEventCfg === undefined) {
        result.push(
            <Text>
                Event <b>{logData.event}</b>
            </Text>
        );
    } else {
        for (const part of thisEventCfg.content) {
            if (typeof part === "string") {
                result.push(<Text>{part}</Text>);
            } else {
                let type = part.type;
                if (type === "key") type = logData.data_key;
                const component: (data: Object) => React.Component =
                    components[type];
                if (component !== null && component !== undefined)
                    result.push(
                        component({
                            dataKey: "data_" + part.dataKey,
                            log: logData,
                            options: thisEventCfg.options ?? null,
                            key: result.length,
                        })
                    );
            }
        }
    }

    return <Stack direction={"row"} flexWrap="wrap">{result}</Stack>;
}

const components = {
    player: (data) => (
        <Player playerId={data.log[data.dataKey]} key={data.key} />
    ), // READY
    match: (data) => (
        <Match
            matchId={data.log[data.dataKey]}
            current={
                data.log.data_value !== null && data.log.data_value === "1"
            }
            key={data.key}
        />
    ), // READY
    profile: (data) => <Profile key={data.key} />, // READY
    replace: (data) => <Replace text={data.log[data.dataKey]} key={data.key} />, // READY
    options: (data) => (
        <Options
            options={data.options}
            value={data.log[data.dataKey]}
            key={data.key}
        />
    ), // READY
    btn: (data) => <Btn text={data.log[data.dataKey]} key={data.key} />, // READY
    premadewr: (data) => (
        <PremadeWr players={data.log[data.dataKey]} key={data.key} />
    ), // READY
};

function SessionLogs({ selectedSession, logs }) {
    const dateColor = useColorModeValue("secondary.100", "light.500");
    const startedDate = new Date(selectedSession.started);
    const startedDateFormatted = strftime("%a %e %b %Y %Hh%M", startedDate);
    let endedDate = null;
    let endedDateFormatted = null;
    const [playerData, setPlayerData]: [PlayerType, Dispatch<any>] =
        useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        API.get(
            config.faceitProxy +
                config.faceitEndpoints.user +
                selectedSession.playerid
        )
            .then((res) => setPlayerData(res.data.payload))
            .catch((error) => console.log(error));
    });

    if (selectedSession.ended !== null) {
        endedDate = new Date(selectedSession.ended);
        endedDateFormatted = strftime("%e %b %Hh%M", endedDate);
    }

    const logsList = [];
    if (logs !== null) {
        for (const log of logs) {
            const date = new Date(log.at);
            const dateFormatted = strftime("%H:%M:%S", date);

            logsList.push(
                <HStack key={logsList.length} alignItems="flex-start">
                    <Text color={dateColor}>{dateFormatted}</Text>
                    <EventLog logData={log} />
                </HStack>
            );
        }
    }

    const nickname =
        playerData !== null ? playerData.nickname : selectedSession.nickname;

    return (
        <>
            <Text fontWeight={"bold"} fontSize={18}>
                Session {selectedSession.uniquekey}
            </Text>
            <Text fontSize={16} py={2}>
                Started {startedDateFormatted}
                {endedDate !== null ? ` - Ended ${endedDateFormatted}` : ""}
            </Text>
            <HStack>
                <Image
                    alt="Profile Avatar"
                    fit="fill"
                    fallbackSrc={config.defaultAvatar}
                    ignoreFallback={playerData !== null}
                    w={8}
                    h={"full"}
                    rounded={"full"}
                    border={"1px solid"}
                    borderColor={"secondary.600"}
                    src={playerData !== null ? playerData.avatar : ""}
                    sx={{
                        imageRendering: "-webkit-optimize-contrast",
                    }}
                />
                <Heading
                    fontSize={18}
                    onClick={() => navigate("/player/" + nickname)}
                    cursor="pointer"
                    _hover={{ color: "brand.500" }}
                    _active={{ color: "brand.600" }}
                    style={{ transition: "color 150ms" }}
                >
                    {nickname}
                </Heading>
            </HStack>
            <Divider my={4} />
            <VStack alignItems={"flex-start"}>
                {logs !== null ? logsList : <CustomSpinner alignSelf="left" />}
            </VStack>
        </>
    );
}

function RecentSessions({ sessionsData, selectedSession, setSelectedSession }) {
    let sessionsList = [];

    if (sessionsData === null) return null;

    for (const session of sessionsData.sessions) {
        sessionsList.push(
            <SessionPlayer
                selectedSession={selectedSession}
                onClick={() => setSelectedSession(session)}
                session={session}
                px={2}
                noredirect
                key={session.uniquekey}
            />
        );
    }

    return <>{sessionsList}</>;
}

function OnlineUsers({ onlineData /*, playersData*/ }) {
    let onlineList = [];
    const guestColor = useColorModeValue("secondary.100", "light.500");

    if (onlineData === null) return null;

    for (const user of onlineData.users) {
        onlineList.push(<SessionPlayer user={user} key={user.socketid} />);
    }

    if (onlineData.guests !== 0) {
        onlineList.push(
            <HStack key={"guests"}>
                <Image
                    alt="Profile Avatar"
                    fit="fill"
                    fallbackSrc={config.defaultAvatar}
                    w={8}
                    h={"full"}
                    rounded={"full"}
                    border={"1px solid"}
                    borderColor={"secondary.600"}
                    src={
                        /*context.player !== null ? context.player.avatar : */ ""
                    }
                    sx={{
                        imageRendering: "-webkit-optimize-contrast",
                    }}
                />
                <Heading fontSize={17} color={guestColor} fontWeight="normal">
                    {onlineData.guests} guest
                    {onlineData.guests !== 1 ? "s" : ""}
                </Heading>
            </HStack>
        );
    }

    return <>{onlineList}</>;
}

export default AdminPage;
