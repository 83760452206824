import {
    Alert,
    AlertIcon,
    AlertTitle,
    Flex,
    Heading,
    HStack,
    Image,
    Stack,
    Text,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import { CustomBox } from "../components/CustomBox";
import PageLayout from "../components/layout/PageLayout";
import config from "../config";
import * as Logs from "../utils/Logs";
import API from "../services/API";
import { Dispatch, useContext, useEffect, useState } from "react";
import AuthContext from "../contexts/AuthContext";
import { Flip, toast } from "react-toastify";
import PlayerType from "../types/PlayerType";
import CustomBtn from "../components/CustomBtn";
import { CustomSpinner } from "../components/CustomSpinner";
import SearchBox from "../components/SearchBox";
import { useNavigate } from "react-router-dom";

const TeammatesPage = () => {
    const context = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [chosenPlayer, setChosenPlayer] = useState(null);
    const [chosenPlayerNickname, setChosenPlayerNickname] = useState(null);
    const [oldPlayer, setOldPlayer] = useState(null);
    const [teammatesData, setTeammatesData] = useState(null);
    const [selectedMap, setSelectedMap] = useState("all");

    useEffect(() => {
        if (context.signed) {
            setChosenPlayer(context.user.id);
            setChosenPlayerNickname(
                context.user === null
                    ? context.player.nickname
                    : context.user.nickname
            );
            setOldPlayer(
                context.user === null
                    ? context.player.nickname
                    : context.user.nickname
            );
        }
    }, [context.signed]);

    useEffect(() => {
        window.scrollTo(0, 0);
        Logs.post("page", { page: "/teammates" }, context.sessionkey);
    }, [context.sessionkey]);

    const alertBorderColor = useColorModeValue("#fbb", "#faa");
    const inputBgColor = useColorModeValue("light.300", "secondary.450");
    const inputBgColorHover = useColorModeValue("light.200", "#1e1e1e");
    const toastClass = useColorModeValue(
        "Toastify__white-background",
        "Toastify__black-background"
    );

    if (!context.signed) {
        return (
            <PageLayout py={{ base: 4, md: 8 }} pagetitle={"Best Teammates"}>
                <VStack align={"flex-start"} spacing={3}>
                    <Heading fontSize={"3xl"} mb={2}>
                        Best Teammates
                    </Heading>
                    <Flex
                        w={"full"}
                        justifyContent="space-between"
                        direction={{ base: "column", md: "row" }}
                        flexWrap="wrap"
                    >
                        <Alert
                            status="error"
                            borderRadius={6}
                            border="1px solid"
                            borderColor={alertBorderColor}
                        >
                            <AlertIcon />
                            <AlertTitle mr={2}>
                                You need to be logged in to access this
                                resource.
                            </AlertTitle>
                        </Alert>
                    </Flex>
                </VStack>
            </PageLayout>
        );
    }

    const playerClickHandler = (data) => {
        const nickname = data.nickname;
        if (nickname === chosenPlayerNickname) return;
        setChosenPlayerNickname(nickname);
        setChosenPlayer(null);
        setIsLoading(true);
    };

    const goClickHandler = () => {
        if (teammatesData !== null && chosenPlayer === teammatesData.player)
            return;
        setIsLoading(true);
        setTeammatesData(null);
        setIsDataLoading(true);
        API.get(config.apiEndpoints.teammates + chosenPlayer)
            .then((res) => {
                const data = res.data;
                setTeammatesData({ ...data, player: chosenPlayer });
            })
            .catch((err) => {
                console.log(err);
                toast.error("Could not retrieve data.", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    closeButton: false,
                    transition: Flip,
                    className: toastClass,
                });
            })
            .finally(() => {
                setIsLoading(false);
                setIsDataLoading(false);
            });
        Logs.post("teammates", { playerid: chosenPlayer }, context.sessionkey);
    };

    return (
        <PageLayout py={{ base: 4, md: 8 }} pagetitle="Best Teammates">
            <VStack align={"flex-start"} spacing={3}>
                <Heading fontSize={"3xl"} mb={2}>
                    Best Teammates
                </Heading>
                <Stack
                    mt={{ base: 6, md: 12 }}
                    spacing={{ base: 0, md: 6 }}
                    direction={{ base: "column", md: "row" }}
                    w="full"
                >
                    <Flex flex={1} direction={"column"} w="full">
                        <Stack pb={4}>
                            <Text fontSize={18} fontWeight="bold">
                                Choose Player
                            </Text>
                            <SearchBox
                                onPlayerClick={(data) =>
                                    playerClickHandler(data)
                                }
                            />
                        </Stack>
                        <CustomBox py={3}>
                            <VStack alignItems={"flex-start"}>
                                <ChosenPlayer
                                    nickname={chosenPlayerNickname}
                                    setChosenPlayer={setChosenPlayer}
                                    setChosenPlayerNickname={
                                        setChosenPlayerNickname
                                    }
                                    oldPlayer={oldPlayer}
                                    setOldPlayer={setOldPlayer}
                                    setIsLoading={setIsLoading}
                                />
                            </VStack>
                        </CustomBox>
                        <CustomBtn
                            noicon
                            text="Go"
                            isLoading={isLoading}
                            onClick={goClickHandler}
                            isDisabled={false}
                        />
                        {teammatesData !== null &&
                            teammatesData.player !== undefined && (
                                <>
                                    <Flex direction={"column"} w="full" py={5}>
                                        <Heading
                                            fontSize={21}
                                            mt={{ base: 6, md: "4px" }}
                                            mb={2}
                                        >
                                            Selected player
                                        </Heading>
                                        <SelectedPlayer
                                            id={teammatesData.player}
                                            chosenPlayer={chosenPlayer}
                                            setChosenPlayer={setChosenPlayer}
                                            setChosenPlayerNickname={
                                                setChosenPlayerNickname
                                            }
                                            selectedPlayer={
                                                teammatesData.player
                                            }
                                        />
                                    </Flex>
                                    <VStack
                                        w="full"
                                        py={0}
                                        spacing={5}
                                        alignItems="flex-start"
                                    >
                                        <Heading
                                            fontSize={21}
                                            mt={{ base: 6, md: "4px" }}
                                            mb={2}
                                        >
                                            Select Map
                                        </Heading>
                                        <MapSelection
                                            selectedMap={selectedMap}
                                            setSelectedMap={setSelectedMap}
                                        />
                                    </VStack>
                                </>
                            )}
                    </Flex>
                    <Flex
                        flex={3}
                        direction={"column"}
                        w="full"
                        pt={{ base: 5, md: 0 }}
                    >
                        <CustomBox py={3}>
                            {teammatesData !== null || isDataLoading ? (
                                <TeammatesSection
                                    data={teammatesData}
                                    selectedMap={selectedMap}
                                    chosenPlayer={chosenPlayer}
                                    setChosenPlayer={setChosenPlayer}
                                    setChosenPlayerNickname={
                                        setChosenPlayerNickname
                                    }
                                />
                            ) : (
                                <Text>Choose a player and press "Go".</Text>
                            )}
                        </CustomBox>
                    </Flex>
                </Stack>
            </VStack>
        </PageLayout>
    );
};

function ChosenPlayer({
    nickname,
    setChosenPlayer,
    setChosenPlayerNickname,
    oldPlayer,
    setOldPlayer,
    setIsLoading,
}) {
    const [playerData, setPlayerData]: [PlayerType, Dispatch<any>] =
        useState(null);
    const context = useContext(AuthContext);
    const navigate = useNavigate();

    const toastClass = useColorModeValue(
        "Toastify__white-background",
        "Toastify__black-background"
    );

    useEffect(() => {
        setPlayerData(null);
        if (nickname === null) return;
        API.get(config.faceitProxy + config.faceitEndpoints.player + nickname)
            .then((res) => {
                const data: PlayerType = res.data.payload;
                setPlayerData(data);
                setChosenPlayer(data.id);
                setOldPlayer(data.nickname);
            })
            .catch((error) => {
                console.log(error);
                setChosenPlayerNickname(oldPlayer);
                toast.warning("Invalid player.", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    closeButton: false,
                    transition: Flip,
                    className: toastClass,
                });
            })
            .finally(() => setIsLoading(false));
    }, [nickname]);

    const clickHandler = () => {
        if (playerData === null) return;
        Logs.post(
            "click",
            {
                type: "player",
                value: playerData.id,
            },
            context.sessionkey
        );
        navigate("/player/" + nickname);
    };

    return (
        <HStack width={"full"} py={1} borderRadius={8}>
            <Image
                alt="Profile Avatar"
                fit="fill"
                fallbackSrc={config.defaultAvatar}
                w={8}
                h={8}
                rounded={"full"}
                border={"1px solid"}
                borderColor={"secondary.600"}
                src={playerData !== null ? playerData.avatar : ""}
                sx={{
                    imageRendering: "-webkit-optimize-contrast",
                }}
            />
            <Heading
                fontSize={17}
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                width={"full"}
                cursor="pointer"
                _hover={{ color: "brand.500" }}
                _active={{ color: "brand.600" }}
                style={{ transition: "color 150ms" }}
                onClick={clickHandler}
            >
                {nickname}
            </Heading>
        </HStack>
    );
}

function TeammatesSection({
    data,
    selectedMap,
    chosenPlayer,
    setChosenPlayer,
    setChosenPlayerNickname,
}) {
    const redColor = useColorModeValue("faceit.LOSS_LIGHT", "faceit.LOSS");
    const greenColor = useColorModeValue("faceit.WIN_LIGHT", "faceit.WIN");
    const greyColor = useColorModeValue("secondary.100", "light.600");

    if (data === null || data.overall === undefined) {
        return <CustomSpinner mt={1} alignSelf="flex-start" />;
    }

    const teammatesData =
        selectedMap === "all" ? data.overall : data.maps[selectedMap];

    return (
        <Stack
            mt={1}
            spacing={{ base: 0, md: 6 }}
            // direction={{ base: "column", lg: "column" }}
            w="full"
        >
            <Flex
                w="full"
                justifyContent="space-between"
                // direction={{ base: "column", md: "row" }}
                direction={"column"}
                height={{ base: "auto", lg: 480 }}
                flexWrap="wrap"
            >
                {teammatesData.map((p) => {
                    let wr = Math.round((p.wins / p.matches) * 1000) / 10;
                    if (isNaN(wr)) wr = 0;

                    return (
                        <HStack
                            key={p.id}
                            justifyContent={"flex-start"}
                            flexBasis={{
                                base: "auto",
                                lg: 12,
                            }}
                        >
                            <SelectedPlayer
                                id={p.id}
                                chosenPlayer={chosenPlayer}
                                setChosenPlayer={setChosenPlayer}
                                setChosenPlayerNickname={
                                    setChosenPlayerNickname
                                }
                                selectedPlayer={data.player}
                            />
                            <Heading
                                color={wr < 50 ? redColor : greenColor}
                                fontSize={21}
                                title={
                                    p.wins + " win" + (p.wins === 1 ? "" : "s")
                                }
                                cursor="help"
                            >
                                {wr}%
                            </Heading>
                            <Heading fontSize={12} color={greyColor}>
                                {" " +
                                    p.matches +
                                    (p.matches === 1 ? " match" : " matches")}
                            </Heading>
                        </HStack>
                    );
                })}
            </Flex>
        </Stack>
    );
}

function SelectedPlayer({
    id,
    chosenPlayer,
    setChosenPlayer,
    setChosenPlayerNickname,
    selectedPlayer,
}) {
    const [playerData, setPlayerData]: [PlayerType, Dispatch<any>] =
        useState(null);
    const context = useContext(AuthContext);
    const navigate = useNavigate();

    const greyColor = useColorModeValue("secondary.100", "light.600");

    useEffect(() => {
        API.get(config.faceitProxy + config.faceitEndpoints.user + id)
            .then((res) => {
                const data: PlayerType = res.data.payload;
                setPlayerData(data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const clickHandler = () => {
        if (playerData === null) return;

        // if === selected then navigate
        if (playerData.id === selectedPlayer)
            return navigate("/player/" + playerData.nickname);

        // if === chosen player then return
        if (playerData.id === chosenPlayer) return;

        // TODO: SET SELECTED
        setChosenPlayer(playerData.id);
        setChosenPlayerNickname(playerData.nickname);

        Logs.post(
            "click",
            {
                type: "player",
                value: playerData.id,
            },
            context.sessionkey
        );
    };

    return (
        <HStack width={"full"} maxW={40} py={1} borderRadius={8}>
            <Image
                alt="Profile Avatar"
                fit="fill"
                fallbackSrc={config.defaultAvatar}
                w={8}
                h={8}
                rounded={"full"}
                border={"1px solid"}
                borderColor={"secondary.600"}
                src={playerData !== null && playerData.avatar}
                sx={{
                    imageRendering: "-webkit-optimize-contrast",
                }}
            />
            <Heading
                fontSize={17}
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                color={playerData === null ? greyColor : ""}
                width={"auto"}
                title={playerData === null ? "Loading..." : playerData.nickname}
                cursor="pointer"
                _hover={{ color: "brand.500" }}
                _active={{ color: "brand.600" }}
                style={{ transition: "color 150ms" }}
                onClick={clickHandler}
            >
                {playerData === null ? "Loading..." : playerData.nickname}
            </Heading>
        </HStack>
    );
}

function MapSelection({ selectedMap, setSelectedMap }) {
    const bgColor = useColorModeValue("#e5e8ea", "#101010");
    const unselectedOpacity = ".4";
    const context = useContext(AuthContext);

    return (
        <Flex w={"full"} flexWrap={"wrap"} justifyContent="space-between">
            <div
                style={{
                    position: "relative",
                    flexBasis: "33%",
                    marginBottom: "40px",
                    opacity: selectedMap === "all" ? ".99" : unselectedOpacity,
                    cursor: "pointer",
                    transition: "opacity 150ms",
                }}
                onClick={() => {
                    if (selectedMap === "all") return;
                    setSelectedMap("all");
                    Logs.post(
                        "click",
                        {
                            type: "btn",
                            value: "All",
                        },
                        context.sessionkey
                    );
                }}
            >
                <Text
                    width={"88px"}
                    textAlign="center"
                    fontWeight={"bold"}
                    // height={"60px"}
                >
                    All
                </Text>
                <div
                    style={{
                        position: "absolute",
                        top: "-22px",
                        left: "-4px",
                        zIndex: "-100",
                        opacity: ".65",
                        width: "96px",
                        height: "57px",
                        boxShadow: `0 0 7px 8px ${bgColor} inset`,
                        imageRendering: "-webkit-optimize-contrast",
                        backgroundSize: "114px 57px",
                        backgroundImage: `url(/images/map_cover/all.png)`,
                        // borderRadius: "8px",
                    }}
                ></div>
            </div>
            {config.mapPool.map((m) => {
                const mapCfg = config.maps[m];

                const clickHandler = () => {
                    if (selectedMap === m) return;
                    setSelectedMap(m);
                    Logs.post(
                        "click",
                        {
                            type: "btn",
                            value: mapCfg.label,
                        },
                        context.sessionkey
                    );
                };

                return (
                    <div
                        style={{
                            position: "relative",
                            flexBasis: "33%",
                            marginBottom: "40px",
                            opacity:
                                selectedMap === m ? ".99" : unselectedOpacity,
                            cursor: "pointer",
                            transition: "opacity 150ms",
                        }}
                        key={m}
                        onClick={clickHandler}
                    >
                        <Text
                            width={"88px"}
                            textAlign="center"
                            fontWeight={"bold"}
                            // height={"60px"}
                        >
                            {mapCfg.label}
                        </Text>
                        <div
                            style={{
                                position: "absolute",
                                top: "-22px",
                                left: "-4px",
                                zIndex: "-100",
                                opacity: ".65",
                                width: "96px",
                                height: "57px",
                                boxShadow: `0 0 7px 8px ${bgColor} inset`,
                                imageRendering: "-webkit-optimize-contrast",
                                backgroundSize: "114px 57px",
                                backgroundImage: `url(${mapCfg.cover})`,
                            }}
                        ></div>
                    </div>
                );
            })}
        </Flex>
    );
}

export default TeammatesPage;
