import { Button } from "@chakra-ui/react";
import FaceitIcon from "./icons/FaceitIcon";

function CustomBtn(props) {
    return (
        <Button
            isLoading={props.isLoading}
            loadingText={props.loadingText ?? "Loading"}
            variant={props.variant ?? "primary"}
            // bgColor={props.bgColor ?? null}
            // color={props.textColor ?? null}
            size={"md"}
            height={props.height ?? 8}
            fontSize={props.fontSize ?? 18}
            fontWeight="bold"
            px={5}
            leftIcon={
                props.noicon === undefined ? props.icon ?? <FaceitIcon /> : null
            }
            isDisabled={props.isDisabled}
            alignSelf={props.alignSelf ?? "auto"}
            onClick={props.onClick}
            ref={props.ref ?? null}
            ml={props.ml ?? null}
            title={props.title ?? null}
        >
            {props.text ?? ""}
        </Button>
    );
}

export default CustomBtn;
