import { EmailIcon } from "@chakra-ui/icons";
import {
    Box,
    Container,
    Divider,
    Flex,
    Hide,
    HStack,
    IconButton,
    Link,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import PortugalFlag from "../icons/countryFlags/PortugalFlag";
import Logo from "../icons/Logo";
import * as Logs from "../../utils/Logs";
import config from "../../config";

function Footer() {
    const navigate = useNavigate();
    const date = new Date();
    const context = useContext(AuthContext);

    return (
        <Box px={4}>
            <Container py={5} maxW="container.xl">
                <Divider
                    borderColor={useColorModeValue(
                        "secondary.200",
                        "light.900"
                    )}
                />
                <Flex mt={6} alignItems="center" justifyContent="space-between">
                    <Logo
                        onClick={() => navigate(context.signed ? "/home" : "/")}
                    />
                    <HStack spacing={7}>
                        <Hide below="sm">
                            <HStack>
                                <Text
                                    fontWeight="bold"
                                    color={useColorModeValue(
                                        "secondary.300",
                                        "light.500"
                                    )}
                                    alignSelf="center"
                                >
                                    Made in
                                </Text>
                                <PortugalFlag />
                            </HStack>
                        </Hide>

                        <Text
                            fontWeight="bold"
                            color={useColorModeValue(
                                "secondary.300",
                                "light.500"
                            )}
                        >
                            © {date.getFullYear()} RMEEV
                        </Text>
                        <Link
                            href={"mailto:" + config.contactEmail}
                            paddingTop={1}
                            borderRadius={6}
                            onClick={() => {
                                Logs.post(
                                    "click",
                                    { type: "btn", value: "Footer Email" },
                                    context.sessionkey
                                );
                            }}
                        >
                            <IconButton
                                aria-label="Email"
                                variant="link"
                                tabIndex={-1}
                                icon={
                                    <EmailIcon
                                        color={useColorModeValue(
                                            "secondary.300",
                                            "light.500"
                                        )}
                                    />
                                }
                            />
                        </Link>
                    </HStack>
                </Flex>
            </Container>
        </Box>
    );
}

export default Footer;
