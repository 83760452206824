// import radar from './radar.png';
const radar = require("./radar.png");

const config = {
    config: {
        origin: {
            x: -2476,
            y: 3239 - 1920,
        },
        scale: 4.4,
    },
    file: radar,
};

export default config;
