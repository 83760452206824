import { createContext, useContext, useEffect, useState } from "react";
import API from "../services/API";
// import loginWithFaceit from "../utils/loginWithFaceit";
import { v4 as uuidv4 } from "uuid";
import config from "../config";
import UserType from "../types/UserType";
import PlayerType from "../types/PlayerType";
import { socketio } from "./SocketioContext";

interface AuthContextData {
    signed: boolean;
    user: UserType | null;
    player: PlayerType | null;
    sessionkey: string | null;
    Login(): Promise<void>;
    Logout(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export function AuthContextProvider({ children, ...props }) {
    const [user, setUser] = useState<UserType | null>(null);
    const [player, setPlayer] = useState<PlayerType | null>(null);
    const [sessionkey, setSessionkey] = useState<string | null>(null);

    if (window.localStorage.getItem("login_id") === null)
        window.localStorage.setItem("login_id", uuidv4());

    const login_id = window.localStorage.getItem("login_id");

    if (window.localStorage.getItem("visitor_id") === null)
        window.localStorage.setItem("visitor_id", uuidv4());

    const visitor_id = window.localStorage.getItem("visitor_id");

    API.defaults.headers["Authorization"] = `Bearer ${login_id}`;

    function socketLogin() {
        if (sessionkey === null) return;
        socketio.emit("login", { token: login_id, sessionkey: sessionkey });
    }

    useEffect(socketLogin, [login_id, sessionkey]);

    useEffect(() => {
        Login();
    }, []);

    async function Login() {
        const response = await API.get(config.apiEndpoints.me, {
            cache: false,
        });

        if (response.data.logged_in) {
            setUser(response.data);

            API.get(
                config.faceitProxy +
                    config.faceitEndpoints.user +
                    response.data.id,
                {
                    cache: false,
                }
            ).then((res) => setPlayer(res.data.payload));
        }

        API.post(config.apiEndpoints.event, {
            event: "welcome",
            visitor: visitor_id,
        })
            .then((res) => setSessionkey(res.data.sessionkey))
            .catch((err) => console.log(err));
        // API.defaults.headers["Authorization"] = `Bearer ${response.data.token}`;
    }

    async function Logout() {
        try {
            await API.post(config.apiEndpoints.logout);
        } catch (err) {}

        window.localStorage.setItem("login_id", uuidv4());
        setUser(null);
        setPlayer(null);
        setSessionkey(null);
        socketio.disconnect().connect();
        Login();
    }

    return (
        <AuthContext.Provider
            value={{
                signed: Boolean(user),
                user,
                player,
                sessionkey,
                Login,
                Logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export default AuthContext;
