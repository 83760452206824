import { useColorModeValue } from "@chakra-ui/react";

interface PropTypes {
    blinking?: boolean;
    color?: string;
}

export function StatusDot(props: PropTypes) {
    let color = props.color;
    const onlineColor = useColorModeValue("#219f40", "#32D35A");
    if (color === "green") color = onlineColor;
    const blinking = props.blinking !== undefined && props.blinking;

    return (
        <div
            style={{
                backgroundColor: color ?? onlineColor,
                width: 9,
                height: 9,
                borderRadius: "50%",
                animationName: blinking ? "blinking" : "none",
                animationDuration: "1.15s",
                animationTimingFunction: "ease-in-out",
                animationIterationCount: "infinite",
            }}
        ></div>
    );
}
