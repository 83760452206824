import { HStack } from "@chakra-ui/react";
import Player from "./Player";

function PremadeWr({ players }) {
    const playersIds = JSON.parse(players);
    let playersList = [];

    if (!Array.isArray(playersIds)) return null;

    for (const id of playersIds) {
        playersList.push(<Player playerId={id} key={playersList.length} />);
    }

    return <HStack flexWrap={"wrap"}>{playersList}</HStack>;
}

export default PremadeWr;
