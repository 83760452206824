import "@fontsource/play";
import {
    extendTheme,
    theme as base,
    withDefaultColorScheme,
    withDefaultVariant,
} from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import { createBreakpoints } from "@chakra-ui/theme-tools";

const focusBorderColor = "brand.300";

const config = {
    initialColorMode: "dark",
};

const primaryRing = {
    _focus: {
        ring: 2,
        ringColor: focusBorderColor,
    },
};

const breakpoints = createBreakpoints({
    sm: "30em",
    md: "48em",
    lg: "62em",
    "2lg": "71em",
    xl: "80em",
    "2xl": "96em",
});

const Theme = extendTheme(
    {
        colors: {
            brand: {
                50: "#ffccb3",
                100: "#ffaa80",
                200: "#ff9966",
                300: "#ff884d",
                400: "#ff7733",
                500: "#FF5500",
                600: "#cc4400",
                700: "#b33b00",
                800: "#993300",
                900: "#662200",
            },
            secondary: {
                50: "#747474",
                100: "#595959",
                200: "#454545",
                300: "#383838",
                400: "#2c2c2c",
                450: "#222222",
                475: "#202020",
                500: "#141616",
                600: "#131313",
                700: "#101010",
                800: "#000000",
                900: "#000000",
            },
            light: {
                50: "#ffffff",
                75: "#f7fafc",
                100: "#e5e8ea",
                200: "#d7dbdf",
                300: "#c9ced4",
                400: "#bac2c9",
                450: "#aeb2b9",
                500: "#9ea8b2",
                600: "#828f9b",
                700: "#738290",
                800: "#687582",
                900: "#515b66",
            },
            faceit: {
                WIN: "#32D35A",
                WIN_LIGHT: "#219f40",
                LOSS: "#FF002B",
                LOSS_LIGHT: "#FF002B",
            },
            settings: {
                50: "#cc4400",
                100: "#cc4400",
                200: "#cc4400",
                300: "#cc4400",
                400: "#cc4400",
                500: "#cc4400",
                600: "#cc4400",
                700: "#cc4400",
                800: "#cc4400",
                900: "#cc4400",
            },
        },
        fonts: {
            heading: `Play, ${base.fonts?.heading}`,
            body: `Play, ${base.fonts?.body}`,
        },
        styles: {
            global: (props) => ({
                body: {
                    bgColor: mode("light.100", "secondary.700")(props),
                    letterSpacing: base.letterSpacings.wide,
                },
            }),
        },
        components: {
            Button: {
                variants: {
                    primary: (props) => ({
                        borderRadius: 5,
                        ...primaryRing,
                        color: "light.100",
                        bgColor: "brand.500",
                        _hover: {
                            bgColor: "brand.600",
                            _disabled: {
                                bgColor: "brand.500", // same as normal bgColor
                            },
                        },
                        _active: { bgColor: "brand.700" },
                    }),
                    secondary: (props) => ({
                        borderRadius: 5,
                        ...primaryRing,
                        color: mode("secondary.500", "light.100")(props),
                        bgColor: "transparent",
                        _hover: {
                            bgColor: mode("light.200", "secondary.300")(props),
                            _disabled: {
                                // bgColor: mode(
                                //     "light.300",
                                //     "secondary.200"
                                // )(props),
                            },
                        },
                        _active: {
                            bgColor: mode("light.300", "secondary.200")(props),
                        },
                    }),
                },
            },
            // Input: {},
        },
        config,
        shadows: {
            outline: "0 0 0 3px #ff9966",
        },
    },
    withDefaultColorScheme({
        colorScheme: "secondary",
        components: ["Switch"],
    }),
    withDefaultColorScheme({
        colorScheme: "primary",
        components: ["Checkbox"],
    }),
    withDefaultVariant({
        // @ts-ignore
        variant: "filled",
        components: ["Input", "Select"],
    }),
    withDefaultVariant({
        // @ts-ignore
        variant: "primary",
        components: ["Button"],
    }),
    breakpoints
);

export default Theme;

export { focusBorderColor };
