import { Heading, HStack, Image, Text, useColorModeValue, VStack, Spacer, useDisclosure, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter } from "@chakra-ui/react";
import { Dispatch, Fragment, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Flip, toast } from "react-toastify";
import config from "../../config";
import AuthContext from "../../contexts/AuthContext";
import API from "../../services/API";
import { CommentType } from "../../types/CommentType";
import PlayerType from "../../types/PlayerType";
import { strftime } from "../../utils/strftime";
import { AutoResizeTextarea } from "../AutoResizeTextarea";
import CustomBtn from "../CustomBtn";
import { CustomSpinner } from "../CustomSpinner";
import TrashIcon from "../icons/TrashIcon";
import { focusBorderColor } from "../theme/Theme";
import * as Logs from "../../utils/Logs";

interface PropTypes {
    comments: Array<CommentType>;
    setComments: Dispatch<any>;
    playerId: string;
}

function CommentSection({ comments, setComments, playerId }: PropTypes) {
    const context = useContext(AuthContext);
    const [comment, setComment]: [string, Dispatch<any>] = useState("");
    const [showBtns, setShowBtns]: [boolean, Dispatch<any>] = useState(false);
    const [isLoading, setIsLoading]: [boolean, Dispatch<any>] = useState(false);

    const inputBgColor = useColorModeValue("light.300", "secondary.450");
    const inputBgColorHover = useColorModeValue("light.200", "#1e1e1e");

    const postComment = () => {
        setIsLoading(true);
        API.post(config.apiEndpoints.playerComments.replace("{player_id}", playerId), {
            content: comment,
        })
            .then((res) => {
                const newComment = res.data.comment;
                setComments([newComment, ...comments]);
                toast.success(`Comment added successfully.`, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    closeButton: false,
                    transition: Flip,
                    className: "Toastify__black-background",
                });
                Logs.post("comment", { action: "post", playerid: playerId }, context.sessionkey);
            })
            .catch((err) => {
                toast.error(`Error while posting comment.`, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    closeButton: false,
                    transition: Flip,
                    className: "Toastify__black-background",
                });
                console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
                setComment("");
                setShowBtns(false);
            });
    };

    const removeComment = (commentId: string) => setComments(comments.filter((e) => e.id !== commentId));

    return (
        <VStack alignItems={"flex-start"}>
            <Heading fontSize={22} mt={3} mb={2}>
                Comments Section
            </Heading>
            {context.signed && (
                <HStack w={"full"} pb={4} alignItems="flex-start">
                    <Image
                        alt="Profile Avatar"
                        fit="fill"
                        fallbackSrc={config.defaultAvatar}
                        w={9}
                        h={9}
                        rounded={"full"}
                        border={"1px solid"}
                        borderColor={"secondary.600"}
                        src={context.player !== null ? context.player.avatar : ""}
                        sx={{
                            imageRendering: "-webkit-optimize-contrast",
                        }}
                    />
                    <VStack w="full" alignItems={"flex-end"}>
                        <AutoResizeTextarea
                            focusBorderColor={focusBorderColor}
                            bgColor={inputBgColor}
                            _hover={{
                                bgColor: inputBgColorHover,
                            }}
                            w={"full"}
                            placeholder="Insert your comment here"
                            onChange={(e) => setComment(e.target.value)}
                            onFocus={() => setShowBtns(true)}
                            value={comment}
                        />
                        {showBtns && (
                            <HStack>
                                <CustomBtn
                                    noicon
                                    text="Cancel"
                                    variant="secondary"
                                    onClick={() => {
                                        setComment("");
                                        setShowBtns(false);
                                    }}
                                />
                                <CustomBtn noicon text="Comment" isLoading={isLoading} isDisabled={comment.length === 0} onClick={postComment} />
                            </HStack>
                        )}
                    </VStack>
                </HStack>
            )}
            {comments === null ? (
                <CustomSpinner />
            ) : (
                comments.map((comment) => {
                    return <Comment data={comment} playerId={playerId} removeComment={removeComment} key={comment.id} />;
                })
            )}
        </VStack>
    );
}

interface CommentPropTypes {
    data: CommentType;
    playerId: string;
    removeComment: (string) => void;
}

function Comment({ data, playerId, removeComment }: CommentPropTypes) {
    const navigate = useNavigate();
    const [playerData, setPlayerData]: [PlayerType, Dispatch<any>] = useState(null);
    const date = new Date(data.at);
    const dateFormatted = strftime("%a %e %b %Y %Hh%M", date);
    const context = useContext(AuthContext);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();
    const alertBgColor = useColorModeValue("light.200", "secondary.500");

    useEffect(() => {
        API.get(config.faceitProxy + config.faceitEndpoints.user + data.playerid)
            .then((res) => setPlayerData(res.data.payload))
            .catch((err) => console.log(err));
    }, []);

    const nickname = playerData !== null ? playerData.nickname : data.nickname;
    const playerClickHandler = () => navigate("/player/" + nickname);

    const deleteHandler = () => {
        API.delete(config.apiEndpoints.comment + data.id)
            .then(() => {
                toast.success(`Comment deleted successfully.`, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    closeButton: false,
                    transition: Flip,
                    className: "Toastify__black-background",
                });
                removeComment(data.id);
                Logs.post("comment", { action: "delete", playerid: playerId }, context.sessionkey);
            })
            .catch(() => {
                toast.error("Error while deleting comment.", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    closeButton: false,
                    transition: Flip,
                    className: "Toastify__black-background",
                });
            })
            .finally(onClose);
    };

    let fixedContent = data.content.trim();
    while (fixedContent.endsWith("\n")) {
        fixedContent = fixedContent.substring(0, -2);
    }

    return (
        <>
            <HStack alignItems={"flex-start"} pb={3} w="full">
                <Image
                    alt="Profile Avatar"
                    fit="fill"
                    fallbackSrc={config.defaultAvatar}
                    w={9}
                    h={9}
                    rounded={"full"}
                    border={"1px solid"}
                    borderColor={"secondary.600"}
                    onClick={playerClickHandler}
                    cursor={"pointer"}
                    src={playerData !== null ? playerData.avatar : ""}
                    sx={{
                        imageRendering: "-webkit-optimize-contrast",
                    }}
                />
                <VStack alignItems={"flex-start"} fontSize={18} spacing={1} w="full">
                    <HStack alignItems={"baseline"} w="full">
                        <Text fontWeight={"bold"} onClick={playerClickHandler} cursor="pointer" _hover={{ color: "brand.500" }} _active={{ color: "brand.600" }} style={{ transition: "color 150ms" }}>
                            {nickname}
                        </Text>
                        <Text color={"light.700"} fontSize={14}>
                            {dateFormatted}
                        </Text>
                        {context.signed && (data.playerid === context.user.id || context.user.permission === "superadmin") && (
                            <>
                                <Spacer />
                                <TrashIcon fontSize={15} cursor="pointer" onClick={onOpen} />
                            </>
                        )}
                    </HStack>
                    <Text>
                        {fixedContent.split("\n").map((line, key) => (
                            <Fragment key={key}>
                                {line}
                                <br />
                            </Fragment>
                        ))}
                    </Text>
                </VStack>
            </HStack>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay>
                    <AlertDialogContent bgColor={alertBgColor}>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete Comment
                        </AlertDialogHeader>

                        <AlertDialogBody>Are you sure? You can't undo this action afterwards.</AlertDialogBody>

                        <AlertDialogFooter>
                            <CustomBtn ref={cancelRef} variant="secondary" onClick={onClose} noicon text="Cancel" />
                            <CustomBtn colorScheme="red" onClick={deleteHandler} ml={3} noicon text="Delete" />
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
}

export default CommentSection;
