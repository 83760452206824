// import radar from './radar.png';
const radar = require("./radar.png");

const config = {
    config: {
        origin: {
            x: -2087,
            y: 3870 - 2700,
        },
        scale: 4.9,
    },
    file: radar,
};

export default config;
