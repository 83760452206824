// import radar from './radar.png';
const radar = require("./radar.png");

const config = {
    config: {
        origin: {
            x: -2477 + 50,
            y: 2392 + 50,
        },
        scale: 4.7,
    },
    file: radar,
};

export default config;
