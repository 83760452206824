import {
    ChevronDownIcon,
    Icon,
    MoonIcon,
    RepeatIcon,
    SunIcon,
} from "@chakra-ui/icons";
import {
    Button,
    Heading,
    HStack,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spacer,
    Text,
    useColorMode,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import { CustomBox } from "../components/CustomBox";
import PageLayout from "../components/layout/PageLayout";
import ReactSwitch from "react-switch";
import LanguageIcon from "../components/icons/LanguageIcon";
import EnglishFlag from "../components/icons/countryFlags/EnglishFlag";
import PortugalFlag from "../components/icons/countryFlags/PortugalFlag";
import CustomBtn from "../components/CustomBtn";
import { useContext, useEffect, useState } from "react";
import config from "../config";
import AuthContext from "../contexts/AuthContext";
import * as Logs from "../utils/Logs";

const SettingsPage = () => {
    // const colorModeIcon = useColorModeValue(<SunIcon />, <MoonIcon />);
    const { colorMode, toggleColorMode } = useColorMode();
    const [isReplaying, setIsReplaying] = useState(false);
    const context = useContext(AuthContext);

    const loader = document.querySelector(".loader");
    const animatedLogo: any = document.querySelector(".logo");
    const hideLoader = () => loader.classList.add("loader--hide");
    const showLoader = () => {
        setIsReplaying(true);
        loader.classList.remove("loader--displaynone");
        // reload object tag
        animatedLogo.data = animatedLogo.data;
        setTimeout(() => loader.classList.remove("loader--hide"), 150);
        setTimeout(hideLoader, config.animatedLogoTime);
        setTimeout(() => {
            loader.classList.add("loader--displaynone");
            setIsReplaying(false);
        }, config.animatedLogoTime + 300);
        Logs.post(
            "setting",
            {
                key: "Replay Intro",
                value: "Replaying",
            },
            context.sessionkey
        );
    };

    useEffect(() => {
        window.scroll(0, 0);
        Logs.post("page", { page: "/settings" }, context.sessionkey);
    }, [context.sessionkey]);

    const darkModeOnChangeHandler = (to) => {
        toggleColorMode();
        Logs.post(
            "setting",
            {
                key: "Use Dark Mode",
                value: to ? "ON" : "OFF",
            },
            context.sessionkey
        );
    };

    return (
        <PageLayout py={{ base: 4, md: 8 }} pagetitle={"Settings"}>
            <VStack align={"flex-start"} spacing={3}>
                <Heading fontSize={"3xl"} mb={1}>
                    Settings
                </Heading>
                <CustomBox py={4} px={6} fontSize={17}>
                    <HStack spacing={4}>
                        {/* {colorModeIcon} */}
                        <MoonIcon />
                        <Text mr="auto">Use Dark Mode</Text>
                        <Spacer />
                        <ReactSwitch
                            checked={colorMode === "dark"}
                            onChange={darkModeOnChangeHandler}
                            onColor="#ff9966"
                            onHandleColor="#FF5500"
                            handleDiameter={20}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(255, 85, 0, 0.2)"
                            height={14}
                            width={38}
                        />
                    </HStack>
                </CustomBox>
                <CustomBox py={3} px={6} fontSize={17}>
                    <HStack spacing={4}>
                        <LanguageIcon />
                        <Text mr="auto">Change language</Text>
                        <Spacer />
                        <Menu>
                            <MenuButton
                                as={Button}
                                rightIcon={<ChevronDownIcon />}
                                disabled
                            >
                                <EnglishFlag />
                                &nbsp;&nbsp;English
                            </MenuButton>
                            <MenuList
                                bgColor={useColorModeValue(
                                    "light.75",
                                    "secondary.475"
                                )}
                            >
                                <MenuItem>
                                    <EnglishFlag />
                                    &nbsp;&nbsp;English
                                </MenuItem>
                                <MenuItem>
                                    <PortugalFlag />
                                    &nbsp;&nbsp;Portuguese
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </HStack>
                </CustomBox>
                <CustomBox py={3} px={6} fontSize={17}>
                    <HStack spacing={4}>
                        <RepeatIcon />
                        <Text mr="auto">Replay intro</Text>
                        <Spacer />
                        <CustomBtn
                            isLoading={isReplaying}
                            loadingText="Replaying..."
                            text="Replay"
                            fontSize={16.5}
                            icon={<RepeatIcon />}
                            onClick={showLoader}
                        />
                    </HStack>
                </CustomBox>
            </VStack>
        </PageLayout>
    );
};

export default SettingsPage;
