const eventConfig = {
    page: { content: ["Page", { type: "replace", dataKey: "value" }] },
    player_page: { content: ["Page", { type: "player", dataKey: "value" }] },
    dark_mode: {
        content: [
            "Changed dark mode to ",
            { type: "options", dataKey: "tinyint" },
        ],
        options: {
            0: "OFF",
            1: "ON",
        },
    },
    setting: {
        content: [
            "Changed setting ",
            { type: "replace", dataKey: "key" },
            " to ",
            { type: "replace", dataKey: "value" },
        ],
    },
    click: { content: ["Clicked on ", { type: "key", dataKey: "value" }] },
    critic: {
        content: [
            { type: "options", dataKey: "value" },
            { type: "options", dataKey: "tinyint" },
            " on ",
            { type: "player", dataKey: "key" },
        ],
        options: {
            add: "Added",
            remove: "Removed",
            update: "Added",
            0: "Dislike",
            1: "like",
        },
    },

    maps: { content: ["Maps for ", { type: "match", dataKey: "key" }] },
    maps_viewtype: {
        content: [
            "Changed ",
            { type: "replace", dataKey: "key" },
            " to ",
            { type: "options", dataKey: "tinyint" },
        ],
        options: {
            0: "OFF",
            1: "ON",
        },
    },
    search: {
        content: ["Searched for ", { type: "replace", dataKey: "value" }],
    },
    report: { content: ["Reported ", { type: "player", dataKey: "value" }] },
    premadewr: {
        content: ["Premade WR ", { type: "premadewr", dataKey: "value" }],
    },
    teammates: {
        content: ["Best teammates of ", { type: "player", dataKey: "value" }],
    },
    comment: {
        content: [
            { type: "options", dataKey: "value" },
            " a comment on ",
            { type: "player", dataKey: "key" },
            "page",
        ],
        options: {
            post: "Posted",
            delete: "Deleted",
        },
    },
    pistolRoundData: {
        content: [
            "Get pistol rounds data for ",
            { type: "match", dataKey: "key" },
        ],
    },
};

export default eventConfig;
