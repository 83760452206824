import { useEffect, useState } from "react";
import config from "../config";
import API from "../services/API";
import Calendar from "../components/CalendarGraph";
import { CustomSpinner } from "../components/CustomSpinner";

export default function MatchesGraph({ playerId }) {
    const [isLoading, setIsLoading] = useState(true);
    const [playerMatches, setPlayerMatches] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        setPlayerMatches(null);
        API.get(config.faceitProxy + config.faceitEndpoints.matches + playerId)
            .then((res) => {
                const matches = {};
                for (const match of res.data) {
                    const date = new Date(match.date).toLocaleDateString("en-ZA");
                    if (matches[date]) {
                        matches[date]++;
                    } else {
                        matches[date] = 1;
                    }
                }
                setPlayerMatches(matches);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    }, [playerId]);

    if (isLoading) return <CustomSpinner />;
    if (!playerMatches) return <p>Error fetching matches.</p>;

    // console.log(playerMatches);

    const until = new Date().toLocaleDateString("en-ZA");

    let weekNames = ["", "Mon", "", "Wed", "", "Fri", ""];
    let panelColors = ["#2c3036", "#632a04", "#a34316", "#f05912"];

    let weekLabelAttributes = {
        style: {
            // "width": "25px",
            "font-size": 10,
            fill: "#AAA",
        },
    };
    let monthLabelAttributes = {
        style: {
            "font-size": 10,
            "alignment-baseline": "central",
            fill: "#AAA",
        },
    };
    let panelAttributes = {
        rx: "2px",
        ry: "2px",
        style: {
            cursor: "pointer",
            padding: "5px",
        },
    };

    return (
        <Calendar
            values={playerMatches}
            until={until}
            weekLabelAttributes={weekLabelAttributes}
            monthLabelAttributes={monthLabelAttributes}
            panelAttributes={panelAttributes}
            weekNames={weekNames}
            panelColors={panelColors}
            dateFormat="YYYY/MM/DD"
        />
    );
}
