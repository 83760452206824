import config from "../config";
import { detect } from "detect-browser";

function loginWithFaceit(cb: () => void) {
    const login_id = window.localStorage.getItem("login_id");
    const client_id = config.faceitClientId;
    const response_type = "code";
    const state = login_id;
    const browser = detect();
    const redirect_popup = browser.name === "firefox";

    let url = "https://accounts.faceit.com/?";
    url += "response_type=" + encodeURIComponent(response_type);
    url += "&client_id=" + encodeURIComponent(client_id);
    url += "&redirect_popup=" + encodeURIComponent(redirect_popup);
    url += "&state=" + encodeURIComponent(state);

    const targetWindow = "Login with FACEIT";
    const width = 750;
    const height = 825;
    const features =
        "popup=1,directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width="
            .concat(width.toString(), ",height=")
            .concat(height.toString());

    const closeHandler = (e) => {
        console.log("Login window closed");
        console.log(e);
    };

    let loginWindow = window.open(url, targetWindow, features);
    loginWindow.onbeforeunload = closeHandler;
    loginWindow.addEventListener("beforeunload", closeHandler);

    return loginWindow;
}

export default loginWithFaceit;
