import { createIcon } from "@chakra-ui/icons";

const HandshakeIcon = createIcon({
    displayName: "Handshake Icon",
    viewBox: "0 0 24 24",
    path: [
        <path
            d="M16.4799 10.41C16.0899 10.8 15.4399 10.8 15.0499 10.41L10.5799 5.95002L3.52993 12.99L2.86993 12.36C1.69993 11.19 1.69993 9.29002 2.86993 8.12002L7.10993 3.88002C8.27993 2.71002 10.1799 2.71002 11.3499 3.88002L16.4799 9.00002C16.8699 9.39002 16.8699 10.02 16.4799 10.41ZM17.1799 8.29002C17.9599 9.07002 17.9599 10.34 17.1799 11.12C15.9099 12.39 14.5699 11.34 14.3499 11.12L10.5899 7.36002L5.01993 12.93C4.62993 13.32 4.62993 13.95 5.01993 14.34C5.40993 14.73 6.03993 14.73 6.43993 14.34L11.0599 9.72002L11.7699 10.43L7.14993 15.05C6.75993 15.44 6.75993 16.07 7.14993 16.46C7.53993 16.85 8.16993 16.85 8.56993 16.46L13.1899 11.84L13.8999 12.55L9.27993 17.17C8.88993 17.56 8.88993 18.19 9.27993 18.58C9.66993 18.97 10.2999 18.97 10.6899 18.58L15.3099 13.96L16.0199 14.67L11.3999 19.29C11.0099 19.68 11.0099 20.31 11.3999 20.7C11.7899 21.09 12.4199 21.09 12.8099 20.7L21.1299 12.36C22.2999 11.19 22.2999 9.29002 21.1299 8.12002L16.8899 3.88002C15.7399 2.73002 13.8799 2.71002 12.7099 3.82002L17.1799 8.29002Z"
            fill="currentColor"
            key="hndsic1"
        />,
    ],
});

export default HandshakeIcon;
