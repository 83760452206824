import {
    Avatar,
    Box,
    Button,
    Container,
    Flex,
    HStack,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Stack,
    Switch,
    Text,
    useColorMode,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import Logo from "../icons/Logo";
import FaceitIcon from "../icons/FaceitIcon";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";
import loginWithFaceit from "../../utils/loginWithFaceit";
import { MoonIcon, SettingsIcon, SunIcon } from "@chakra-ui/icons";
import * as Logs from "../../utils/Logs";
import config from "../../config";
import LogoutIcon from "../icons/LogoutIcon";
import AdminIcon from "../icons/AdminIcon";

function Navbar() {
    const { colorMode, toggleColorMode } = useColorMode();
    // const { isOpen, onOpen, onClose } = useDisclosure();
    // bg={useColorModeValue("light.100", "secondary.600")}
    const navigate = useNavigate();
    const context = useContext(AuthContext);

    const menuBgColor = useColorModeValue("light.50", "secondary.500");

    const switchOnChangeHandler = () => {
        Logs.post(
            "dark_mode",
            {
                is: colorMode === "dark" ? 1 : 0,
            },
            context.sessionkey
        );
        toggleColorMode();
    };

    const profileClickHandler = () => {
        if (window.location.pathname === "/player/" + context.player.nickname)
            return;

        Logs.post(
            "click",
            {
                type: "profile",
                value: null,
            },
            context.sessionkey
        );
        navigate("/player/" + context.user.nickname);
    };

    const logoutClickHandler = () => {
        Logs.post(
            "click",
            {
                type: "btn",
                value: "Logout",
            },
            context.sessionkey
        );
        context.Logout();
    };

    const adminClickHandler = () => {
        Logs.post(
            "click",
            {
                type: "btn",
                value: "Admin Dashboard",
            },
            context.sessionkey
        );
        navigate("/admin");
    };

    return (
        <Box px={4}>
            <Container py={5} maxW="container.xl">
                <Flex
                    h={16}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Logo
                        onClick={() => navigate(context.signed ? "/home" : "/")}
                    />

                    <Flex alignItems={"center"} justifyContent="center">
                        <Stack
                            direction={"row"}
                            spacing={6}
                            alignItems="center"
                        >
                            <HStack>
                                <Switch
                                    paddingTop={"2px"}
                                    size="md"
                                    isChecked={colorMode === "dark"}
                                    onChange={switchOnChangeHandler}
                                />
                                {useColorModeValue(<SunIcon />, <MoonIcon />)}
                            </HStack>

                            {(context.signed && (
                                <Menu>
                                    <MenuButton
                                        as={Button}
                                        rounded={"full"}
                                        variant={"link"}
                                        cursor={"pointer"}
                                        minW={0}
                                    >
                                        <Avatar
                                            size={"sm"}
                                            src={
                                                context.player === null
                                                    ? ""
                                                    : context.player.avatar
                                            }
                                        />
                                    </MenuButton>
                                    <MenuList
                                        alignItems={"center"}
                                        bgColor={menuBgColor}
                                    >
                                        <VStack py={6} spacing={6}>
                                            <Avatar
                                                size={"2xl"}
                                                src={
                                                    context.player === null
                                                        ? ""
                                                        : context.player.avatar
                                                }
                                                border={"1px"}
                                                color={"secondary.50"}
                                            />
                                            <Text
                                                fontWeight={"bold"}
                                                cursor="pointer"
                                                onClick={profileClickHandler}
                                                _hover={{ color: "brand.500" }}
                                                style={{
                                                    transition: "color 150ms",
                                                }}
                                            >
                                                {context.user.nickname}
                                            </Text>
                                        </VStack>
                                        <MenuDivider />
                                        <MenuItem
                                            onClick={() =>
                                                navigate("/settings")
                                            }
                                        >
                                            <SettingsIcon
                                                fontSize={14}
                                                mr={"14px"}
                                            />
                                            Settings
                                        </MenuItem>
                                        {
                                            /*config.adminPermissions.indexOf(
                                            context.user.permission
                                        ) !== -1*/ context.user.permission ===
                                                "superadmin" && (
                                                <MenuItem
                                                    onClick={adminClickHandler}
                                                >
                                                    <AdminIcon mr={3} />
                                                    Admin Dashboard
                                                </MenuItem>
                                            )
                                        }
                                        <MenuItem onClick={logoutClickHandler}>
                                            <LogoutIcon mr={3} />
                                            Logout
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                            )) || (
                                <Flex flexDir="column" justifyContent="center">
                                    <Button
                                        size="sm"
                                        fontSize={15}
                                        h={7}
                                        borderRadius={5}
                                        variant="primary"
                                        px={6}
                                        leftIcon={<FaceitIcon />}
                                        onClick={() =>
                                            loginWithFaceit(context.Login)
                                        }
                                    >
                                        LOGIN WITH FACEIT
                                    </Button>
                                </Flex>
                            )}
                        </Stack>
                    </Flex>
                </Flex>
            </Container>
        </Box>
    );
}

export default Navbar;
