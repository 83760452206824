import { createIcon } from "@chakra-ui/icons";

const FaceitIcon = createIcon({
  displayName: "FACEIT Icon",
  viewBox: "0 0 22 17",
  path: [
    <path
      key="ficp1"
      d="M21.7885 0.0466312L16.1759 8.46657L0.176069 8.48679C0.176069 8.48679 0.0245156 8.48772 0.00286293 8.5935C-0.0187898 8.69928 0.0894658 8.73671 0.0894658 8.73671L21.6103 16.9864C21.6103 16.9864 21.755 17.0255 21.8484 16.9711C21.9576 16.9077 21.9576 16.7807 21.9576 16.7807L22 0.0889416C22 0.0889416 22 0.0254723 21.9134 0.00431714C21.8268 -0.0168379 21.7885 0.0466312 21.7885 0.0466312Z"
      fill="#EBEFF3"
    />,
  ],
});

export default FaceitIcon;
