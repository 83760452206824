import { Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

function Match({ matchId, current }) {
    const navigate = useNavigate();
    if (matchId === null) return <b>Unavailable Match</b>;
    else
        return (
            <Text
                fontWeight={"bold"}
                cursor="pointer"
                textDecoration={"underline"}
                css={{ transition: "100ms ease" }}
                _hover={{ color: "brand.500" }}
                onClick={() => navigate("/match/" + matchId)}
            >
                {current ? "his current match" : "this match"}
            </Text>
        );
}

export default Match;
