import { Box, useColorModeValue } from "@chakra-ui/react";

export function CustomBox({ children, ...props }) {
    const customBoxBgColor = useColorModeValue("light.300", "secondary.475");

    return (
        <Box
            flexBasis={props.flexBasis ?? ""}
            bgColor={props.bgColor ?? customBoxBgColor}
            borderRadius={props.borderRadius ?? 8}
            px={props.px ?? 4}
            py={props.py ?? 2}
            mb={5}
            p={props.p}
            cursor={props.cursor}
            width={props.width ?? "full"}
            height={props.height ?? "auto"}
            fontSize={props.fontSize}
            onClick={props.onClick}
            title={props.title}
            pos={props.pos ?? null}
            top={props.top ?? null}
            border={props.border ?? null}
            borderColor={props.borderColor ?? null}
            zIndex={props.zIndex ?? null}
        >
            {children}
        </Box>
    );
}
