import { Text } from "@chakra-ui/react";
import config from "../config";

export default function EloBox({ playerData }) {
    if (playerData.games !== undefined && playerData.games.csgo !== undefined) {
        const elo = playerData.games.csgo.faceit_elo;
        const level = playerData.games.csgo.skill_level;

        const [minEloLvl, maxEloLvl] = config.eloLevels[level];
        const progressBarWidth = maxEloLvl
            ? `${((elo - minEloLvl) / (maxEloLvl - minEloLvl)) * 100}%`
            : "100%";

        const levelBelow = config.eloLevels[level - 1];
        const levelAbove = config.eloLevels[level + 1];

        const levelBelowEloDiff = levelBelow ? `-${elo - levelBelow[1]}` : 0;
        const levelAboveEloDiff = maxEloLvl ? `+${levelAbove[0] - elo}` : "∞";

        return (
            <div className="playerSkillDiv">
                <div style={{ marginRight: "4px" }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Text fontSize="11px" alignSelf="flex-end">
                            CSGO
                        </Text>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                fontSize: "15px",
                            }}
                        >
                            {elo}
                            <i
                                className="elo-icon"
                                style={{ marginLeft: "2px" }}
                            ></i>
                        </div>
                    </div>
                    <div>
                        <div
                            style={{
                                margin: "1px 0px",
                                height: "2px",
                                width: "120px",
                                background: "rgb(75, 78, 78)",
                            }}
                        >
                            <div
                                style={{
                                    height: "100%",
                                    width: progressBarWidth,
                                    background: "rgb(255, 85, 0)",
                                }}
                            ></div>
                        </div>
                        <div
                            style={{
                                fontSize: "11px",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            {minEloLvl}
                            <span>
                                {levelBelowEloDiff}/{levelAboveEloDiff}
                            </span>
                            <span>{maxEloLvl ? maxEloLvl : "∞"}</span>
                        </div>
                    </div>
                </div>
                <div
                    title={"Skill Level " + level}
                    className="faceit-level"
                    style={{
                        backgroundImage: `url('/images/faceit_levels/${level}.svg')`,
                    }}
                ></div>
            </div>
        );
    }

    return null;
}
