import { Icon, IconProps } from "@chakra-ui/icons";
import {
    Button,
    Flex,
    Heading,
    Image,
    Stack,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import FaceitIcon from "../components/icons/FaceitIcon";
import PageLayout from "../components/layout/PageLayout";
import { Navigate, useNavigate } from "react-router-dom";

import config from "../config";
import { useContext, useEffect } from "react";
import loginWithFaceit from "../utils/loginWithFaceit";
import AuthContext from "../contexts/AuthContext";

function Index() {
    const navigate = useNavigate();
    const context = useContext(AuthContext);
    const customBoxBgColor = useColorModeValue("light.300", "secondary.500");

    const btnFunction = () => {
        if (context.signed) navigate("/home");
        else loginWithFaceit(context.Login);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (context.signed) {
        return <Navigate to={"/home"} />;
    }

    return (
        <PageLayout py={{ base: 12, md: 24 }}>
            <Stack
                align="center"
                spacing={{ base: 16, md: 10 }}
                direction={{ base: "column", md: "row" }}
            >
                <Stack flex={1} spacing={{ base: 5, md: 10 }}>
                    <Heading fontSize="5xl">
                        Keep track of the good <br /> and bad players
                    </Heading>
                    <Text>
                        Imagine if you could avoid toxic and bad players... This
                        has now become true! With {config.appName} the community
                        can report players they have had bad experiences with.
                    </Text>
                    <Stack
                        spacing={{ base: 4, sm: 6 }}
                        direction={{ base: "column", sm: "row" }}
                    >
                        <Button
                            variant="primary"
                            size="lg"
                            fontWeight="bold"
                            px={6}
                            leftIcon={<FaceitIcon />}
                            onClick={btnFunction}
                        >
                            Get Started
                        </Button>
                    </Stack>
                </Stack>
                <Flex
                    flex={1.4}
                    justify="center"
                    align="center"
                    position="relative"
                    w="full"
                >
                    <Blob
                        w="150%"
                        h="150%"
                        pos="absolute"
                        top="-20%"
                        left={-7}
                        zIndex={-1}
                        color={customBoxBgColor}
                    />
                    <Image
                        alt="Agents"
                        fit="cover"
                        align="center"
                        w="100%"
                        h="100%"
                        src="images/agentsBgImg_v3.png"
                    />
                </Flex>
            </Stack>
            <Stack
                align="center"
                spacing={{ base: 8, md: 4 }}
                direction={{ base: "column", md: "row" }}
                marginY={40}
            >
                <Flex flex={1.2} justify="center" align="center" w="full">
                    <Image
                        alt="Maps Section"
                        fit="cover"
                        align="center"
                        w="100%"
                        h="100%"
                        src="images/maps-section.png"
                    />
                </Flex>
                <Stack flex={1} spacing={{ base: 5, md: 10 }}>
                    <Heading fontSize="5xl">
                        Get all the stats you need <br />
                        one click away
                    </Heading>
                    <Text>
                        The mind games start early on FACEIT. In addition to
                        keeping bad players away, {config.appName} offers player
                        stats for better map picking!
                    </Text>
                    <Stack
                        spacing={{ base: 4, sm: 6 }}
                        direction={{ base: "column", sm: "row" }}
                    ></Stack>
                </Stack>
            </Stack>
            <Stack
                alignItems={"center"}
                spacing={10}
                marginY={{ base: 24, md: 12 }}
            >
                <Heading textAlign={"center"}>
                    Ready to enhance your FACEIT experience?
                </Heading>
                <Button
                    variant="primary"
                    size="lg"
                    fontWeight="bold"
                    px={6}
                    leftIcon={<FaceitIcon />}
                    onClick={btnFunction}
                >
                    SIGN IN AN GET STARTED
                </Button>
            </Stack>
        </PageLayout>
    );
}

export const Blob = (props: IconProps) => {
    return (
        <Icon
            width="full"
            viewBox="0 0 971 661"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M331.45 582.94C42.7796 547.707 -106.319 520.091 263.332 298.218L748.738 31.3344C825.85 -19.7782 851.993 -11.4332 863.635 79.2003L946.918 493.428C995.962 642.345 974.199 678.35 843.532 654.028L331.45 582.94Z"
                fill="currentColor"
            />
        </Icon>
    );
};

export default Index;
