// import radar from './radar.png';
const radar = require("./radar.png");

const config = {
	config: {
		origin: {
			x: -2953 + 35,
			y: 2164 + 810,
		},
		scale: 5.0,
	},
	file: radar
};

export default config;
