import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Flex,
    Heading,
    HStack,
    Image,
    Spacer,
    Stack,
    Text,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import PageLayout from "../components/layout/PageLayout";
import { useNavigate, Navigate } from "react-router-dom";
import LobbyIcon from "../components/icons/LobbyIcon";
import MapsIcon from "../components/icons/MapsIcon";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../contexts/AuthContext";
import ListIcon from "../components/icons/ListIcon";
import { SocketioContext } from "../contexts/SocketioContext";
import * as Logs from "../utils/Logs";
import WinIcon from "../components/icons/WinIcon";
import HandshakeIcon from "../components/icons/HandshakeIcon";
import SearchBox from "../components/SearchBox";
import { StatusDot } from "../components/StatusDot";

const btns = [
    {
        key: "mapsbtn",
        text: "Check Maps",
        icon: <MapsIcon />,
        image: "/images/home_btns/maps.png",
        onClick: null,
        redirect: "/match/current",
    },
    {
        key: "lobbybtn",
        text: "Check Lobby",
        icon: <LobbyIcon />,
        image: "/images/home_btns/lobby.png",
        onClick: null,
        redirect: "/lobby",
    },
    {
        key: "listbtn",
        text: "List of Players",
        icon: <ListIcon />,
        image: "/images/home_btns/list.png",
        onClick: null,
        redirect: "/list",
    },
    {
        key: "premadewrbtn",
        text: "Premade Winrate",
        icon: <WinIcon />,
        image: "/images/home_btns/premade_wr.png",
        onClick: null,
        redirect: "/premade",
    },
    {
        key: "teammatesbtn",
        text: "Best Teammates",
        icon: <HandshakeIcon />,
        image: "/images/home_btns/best_teammates.png",
        onClick: null,
        redirect: "/teammates",
    },
];

function Home() {
    const [online, setOnline] = useState(0);
    const navigate = useNavigate();

    const context = useContext(AuthContext);
    const socketio = useContext(SocketioContext);

    const onlineColor = useColorModeValue("#219f40", "#32D35A");

    useEffect(() => {
        window.scrollTo(0, 0);
        Logs.post("page", { page: "/home" }, context.sessionkey);

        socketio.emit("get_online_count");
        socketio.on("update_online", (data) => {
            setOnline(data.online);
        });

        return () => {
            socketio.off("update_online");
        };
    }, [context.sessionkey]);

    if (!context.signed) return <Navigate to={"/"} />;

    return (
        <PageLayout py={{ base: 4, md: 8 }}>
            <VStack align={"flex-start"} spacing={3}>
                <HStack mb={1} spacing={5} alignItems="center" w={"full"}>
                    <Heading fontSize={"3xl"}>
                        Welcome, {context.user.nickname}.
                    </Heading>
                    <Spacer />
                    <HStack>
                        <StatusDot />
                        <Text fontSize={14}>{online}</Text>
                    </HStack>
                </HStack>
                {/* <Alert status="success" borderRadius={6}>
                    <VStack alignItems={"flex-start"}>
                        <HStack>
                            <AlertIcon mr={1} />
                            <AlertTitle>A new look for the Home page</AlertTitle>
                        </HStack>
                        <AlertDescription>
                            We made some minor tweaks to the design. Also, we
                            implemented the player search function as on the old
                            site. Hope you like!
                        </AlertDescription>
                    </VStack>
                </Alert> */}

                <Flex
                    w={"full"}
                    justifyContent="space-evenly"
                    direction={{ base: "column", md: "row" }}
                    flexWrap="wrap"
                    alignItems={"center"}
                >
                    {btns.map((b) => (
                        <NewButton cfg={b} key={b.key} />
                    ))}
                </Flex>

                <Stack pt={5} pb={8}>
                    <Text fontSize={20}>You can also...</Text>
                    <SearchBox
                        onPlayerClick={(data) =>
                            navigate("/player/" + data.nickname)
                        }
                    />
                </Stack>
            </VStack>
        </PageLayout>
    );
}

interface BtnPropsType {
    key: string;
    text: string;
    icon: JSX.Element;
    image: string;
    onClick: (() => void | JSX.Element) | null;
    redirect: string | null;
}

function NewButton({ cfg }: { cfg: BtnPropsType }) {
    const context = useContext(AuthContext);
    const navigate = useNavigate();
    const onClick = () => {
        Logs.post(
            "click",
            {
                type: "btn",
                value: cfg.text,
            },
            context.sessionkey
        );
        if (cfg.onClick !== null) cfg.onClick();
        if (cfg.redirect !== null) navigate(cfg.redirect);
    };

    return (
        <Box
            flexBasis={{ base: "100%", md: "49%", lg: "31%" }}
            cursor="pointer"
            onClick={onClick}
            rounded={4}
            pos="relative"
            w={"fit-content"}
            my={5}
            _hover={{ color: "brand.500", opacity: 1, "& img": { opacity: 1 } }}
            style={{ transition: "color 150ms" }}
        >
            <Image
                alt={cfg.text}
                fit="contain"
                src={cfg.image}
                sx={{ imageRendering: "-webkit-optimize-contrast" }}
                margin="auto"
                h={"100%"}
                opacity={0.65}
                style={{ transition: "opacity 150ms" }}
            />
            <HStack
                fontSize={20}
                pos="absolute"
                bottom={0}
                w="full"
                justifyContent={"center"}
            >
                {cfg.icon}
                <Text fontWeight={"bold"}>{cfg.text}</Text>
            </HStack>
        </Box>
    );
}

export default Home;
