import { createContext } from "react";
import { io } from "socket.io-client";
import config from "../config";

const socketio = io(config.socketUrl, {
    transports: ["websocket"],
    reconnectionDelay: 5000,
});
const SocketioContext = createContext(null);

socketio.on("welcome", () => {});

// let startTime;
// setInterval(sping, 5000);

// function sping() {
//     startTime = Date.now();
//     socketio.emit("sping");
// }
// setTimeout(sping, 150);

// socketio.on("spong", () => {
//     let latency = Date.now() - startTime;
// });

export { socketio, SocketioContext };
