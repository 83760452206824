import { Spinner } from "@chakra-ui/react";

export function CustomSpinner(props) {
    return (
        <Spinner
            thickness="4px"
            size={props.size ?? "lg"}
            ml={props.ml}
            color="brand.500"
            speed=".6s"
            alignSelf={props.alignSelf ?? "center"}
        />
    );
}
