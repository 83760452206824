import { Heading, HStack, useColorModeValue } from "@chakra-ui/react";
import { createIcon } from "@chakra-ui/icons";
import config from "../../config";

export const LogoIcon = createIcon({
  displayName: "Logo Icon",
  viewBox: "0 0 64 64",
  path: [
    <rect key="lgic1" width="64" height="64" rx="32" fill="currentColor" />,
    <path
      key="lgic2"
      d="M36.2995 19.2065L28.0042 31.9425L4.35618 31.9731C4.35618 31.9731 4.13218 31.9745 4.10018 32.1345C4.06818 32.2945 4.22818 32.3512 4.22818 32.3512L36.0362 44.8297C36.0362 44.8297 36.2501 44.8887 36.3882 44.8065C36.5495 44.7105 36.5495 44.5185 36.5495 44.5185L36.6122 19.2705C36.6122 19.2705 36.6122 19.1745 36.4842 19.1425C36.3562 19.1105 36.2995 19.2065 36.2995 19.2065Z"
      fill="#FF5500"
    />,
    <rect
      key="lgic3"
      x="40.704"
      y="37.568"
      width="19.2"
      height="4.16"
      rx="2.08"
      fill="#FF0000"
    />,
    <rect
      key="lgic4"
      x="40.704"
      y="29.888"
      width="19.2"
      height="4.16"
      rx="2.08"
      fill="#FFDE00"
    />,
    <rect
      key="lgic5"
      x="40.704"
      y="22.208"
      width="19.2"
      height="4.16"
      rx="2.08"
      fill="#24FF00"
    />,
  ],
});

function Logo(props) {
  return (
    <HStack spacing={5} {...props} cursor="pointer">
      <LogoIcon
        fontSize={64}
        color={useColorModeValue("secondary.400", "secondary.700")}
        // color={useColorModeValue("light.200", "secondary.700")}
      />
      <Heading display={{ base: "none", md: "block" }} fontSize={28}>{config.appName}</Heading>
    </HStack>
  );
}

export default Logo;
