import config from "../config";
import API from "../services/API";

function post(event: string, data: Object, sessionkey: string) {
    if (sessionkey === null) return;
    API.post(config.apiEndpoints.event, {
        event: event,
        data: data,
        sessionkey: sessionkey,
    });
}

function get() {}

export { post, get };
