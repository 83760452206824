import { createIcon } from "@chakra-ui/icons";

export const VerifiedIcon = createIcon({
  displayName: "Logo Icon",
  viewBox: "0 0 20 18",
  path: [
    <path
      key={"vfic1"}
      d="M19.1667 9L17.1334 6.675L17.4167 3.6L14.4084 2.91667L12.8334 0.25L10 1.46667L7.16671 0.25L5.59171 2.90833L2.58337 3.58333L2.86671 6.66667L0.833374 9L2.86671 11.325L2.58337 14.4083L5.59171 15.0917L7.16671 17.75L10 16.525L12.8334 17.7417L14.4084 15.0833L17.4167 14.4L17.1334 11.325L19.1667 9ZM8.40837 12.9333L5.24171 9.75833L6.47504 8.525L8.40837 10.4667L13.2834 5.575L14.5167 6.80833L8.40837 12.9333Z"
      fill="currentColor"
    />,
  ],
});
