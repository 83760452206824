import {
    Flex,
    Heading,
    HStack,
    Image,
    Text,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import PageLayout from "../components/layout/PageLayout";
import { useNavigate } from "react-router-dom";
import CustomBtn from "../components/CustomBtn";
import PlusIcon from "../components/icons/PlusIcon";
import { ReactElement, useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../contexts/AuthContext";
import { CustomBox } from "../components/CustomBox";
import API from "../services/API";
import config from "../config";
import { CustomSpinner } from "../components/CustomSpinner";
import { strftime } from "../utils/strftime";
import { ReputationBadge } from "../components/ReputationBadge";
import * as Logs from "../utils/Logs";

function ListPage() {
    const [list, setList] = useState([]);
    const [playersData, setPlayersData] = useState({});

    const [page, setPage] = useState(1);
    const totalPages = useRef(1);
    const addToList = (newData: Array<any>) => setList(list.concat(newData));
    const addToPlayersData = (newData: Array<any>) =>
        setPlayersData({ ...playersData, ...newData });
    const nextPage = () => {
        const newPage = page + 1;
        setPage(newPage);
        setIsLoading(true);
        Logs.post(
            "click",
            { type: "btn", value: "Load more - page " + newPage },
            context.sessionkey
        );
    };
    const [isLoading, setIsLoading] = useState(false);

    const context = useContext(AuthContext);

    useEffect(() => {
        window.scroll(0, 0);
        Logs.post("page", { page: "/list" }, context.sessionkey);
    }, []);

    useEffect(() => {
        API.get(config.apiEndpoints.list + `?page=${page}`)
            .then((res) => {
                const listData = res.data.data;
                addToList(listData);
                totalPages.current = res.data.totalPages;

                const ids = [];
                const keys = Object.keys(playersData);
                for (const player of listData) {
                    if (keys.indexOf(player.id) === -1) ids.push(player.id);
                }
                API.post(
                    config.faceitProxy + config.faceitEndpoints.userList,
                    ids
                )
                    .then((res) => addToPlayersData(res.data.payload))
                    .catch((err) => console.log(err));
            })
            .catch((error) => console.log(error))
            .finally(() => setIsLoading(false));
    }, [page]);

    if (!list.length) {
        return (
            <PageLayout py={{ base: 4, md: 8 }} pagetitle={"List of Players"}>
                <VStack align={"flex-start"} spacing={3}>
                    <Heading fontSize={"3xl"} mb={1}>
                        List of players
                    </Heading>
                    <CustomSpinner />
                </VStack>
            </PageLayout>
        );
    }

    return (
        <PageLayout py={{ base: 4, md: 8 }} pagetitle={"List of Players"}>
            <VStack align={"flex-start"} spacing={3}>
                <Heading fontSize={"3xl"} mb={1}>
                    List of players
                </Heading>
                <Flex
                    w={"full"}
                    justifyContent="space-between"
                    direction={{ base: "column", md: "row" }}
                    flexWrap="wrap"
                >
                    <PlayerList list={list} playersData={playersData} />
                </Flex>
                {(page < totalPages.current || isLoading) && (
                    <CustomBtn
                        alignSelf="center"
                        text="Load more"
                        icon={<PlusIcon />}
                        onClick={nextPage}
                        isLoading={isLoading}
                    />
                )}
            </VStack>
        </PageLayout>
    );
}

function PlayerList({ list, playersData }) {
    let playerList: Array<ReactElement> = [];
    const customBoxBgColor = useColorModeValue("light.300", "secondary.475");
    const navigate = useNavigate();
    const context = useContext(AuthContext);

    for (const player of list) {
        const createdDate = new Date(player.createdAt ?? null);
        const formattedDate = strftime("%e %b %Y, %Hh%M", createdDate);

        const avatar =
            playersData[player.id] !== undefined
                ? playersData[player.id].avatar
                : "";
        const nickname =
            playersData[player.id] !== undefined
                ? playersData[player.id].nickname
                : player.nickname;

        const clickHandler = () => {
            Logs.post(
                "click",
                {
                    type: "player",
                    value: player.id,
                },
                context.sessionkey
            );
            navigate("/player/" + nickname);
        };

        playerList.push(
            <CustomBox
                flexBasis={{
                    base: "100%",
                    md: "49%",
                    lg: "32%",
                }}
                bgColor={customBoxBgColor}
                // height={62}
                key={playerList.length}
                cursor={"pointer"}
                onClick={clickHandler}
                title={`Click to go to ${nickname}'s page`}
            >
                <HStack>
                    <Image
                        alt="Profile Avatar"
                        fit="fill"
                        fallbackSrc={config.defaultAvatar}
                        h={"12"}
                        w={"12"}
                        rounded={"full"}
                        border={"1px solid"}
                        borderColor={"secondary.400"}
                        src={avatar}
                        sx={{ imageRendering: "-webkit-optimize-contrast" }}
                    />
                    <VStack spacing={0} alignItems="flex-start">
                        <HStack>
                            <Text>{nickname}</Text>
                            <ReputationBadge
                                color={config.reputations[player.rep].color}
                                text={config.reputations[player.rep].reputation}
                            />
                        </HStack>
                        <Text fontSize={12}>{formattedDate}</Text>
                    </VStack>
                </HStack>
            </CustomBox>
        );
    }

    if (playerList.length % 3 !== 0) {
        playerList.push(
            <CustomBox
                flexBasis={{
                    base: "100%",
                    md: "49%",
                    lg: "32%",
                }}
                height={0}
                px={0}
                py={0}
                key={playerList.length}
            >
                {null}
            </CustomBox>
        );
    }

    return <>{playerList}</>;
}

export default ListPage;
