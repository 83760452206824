import { Helmet } from "react-helmet";
import { Container } from "@chakra-ui/react";
import Footer from "../ui/Footer";
import Navbar from "../ui/Navbar";
import config from "../../config";

function PageLayout({ children, ...props }) {
    return (
        <>
            {props.topgradientcolor === undefined || (
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: -9999,
                        background: `linear-gradient(to bottom, ${
                            props.topgradientcolor ?? "transparent"
                        }, transparent 300px)`,
                        animation: "fadein 1s ease",
                    }}
                />
            )}
            <Helmet>
                <title>
                    {props.pagetitle
                        ? `${props.pagetitle} - ${config.appName}`
                        : config.appName}
                </title>
            </Helmet>
            <Navbar />
            <Container
                {...props}
                minH="73vh"
                marginBottom={5}
                maxW={props.maxW ?? "container.xl"}
            >
                {children}
            </Container>
            <Footer />
        </>
    );
}

export default PageLayout;
