import { HStack, Text } from "@chakra-ui/react";
import config from "../config";
import { VerifiedIcon } from "./icons/VerifiedIcon";

function ProfileBadge(props) {
    const type = props.type ?? "verified";
    if (types[type] === undefined) return <></>;

    const bgColor = types[type].color;
    const icon = types[type].icon;
    const label = types[type].label;

    return (
        <HStack
            bgColor={bgColor}
            px={4}
            py={0.5}
            borderRadius={6}
            mb={props.mb ?? 0}
            width={props.width ?? "auto"}
        >
            {icon}
            <Text fontWeight={"bold"} fontSize={18} color="white">
                {label}
            </Text>
        </HStack>
    );
}

const types = {
    verified: {
        icon: <VerifiedIcon color={"white"} fontSize={"2xl"} />,
        label: `FACEIT Verified`,
        color: "brand.500",
    },
    admin: {
        icon: <VerifiedIcon color={"white"} fontSize={"2xl"} />,
        label: `${config.appName} Admin`,
        color: "#0FB9A4",
    },
};

export default ProfileBadge;
