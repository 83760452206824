// import radar from './radar.png';
const radar = require("./radar.png");

const config = {
    config: {
        origin: {
            x: -4831 + 50,
            y: 1781 + 1750,
        },
        scale: 5.2,
    },
    file: radar,
};

export default config;
